import React from 'react'
import Header from '../components/Header/Header'

const AuthLayout = ({ children }) => {
    return (
        <>
            <div className='main-wrapper'>
                <Header />
                {children}
            </div>
        </>
    )
}

export default AuthLayout