import React, { useEffect, useState } from "react";
import PlansBanner from "./PlansBanner/PlansBanner";
import Product from "./ChooseVehicleType/ChooseVehicleType";
import ServiceCoverage from "./ServiceCoverage/ServiceCoverage";
import PlansDetail from "./PlansDetail/PlansDetail";
import JoinUs from "./../../components/Common/JoinUs/JoinUs";
import OurProduct from "./OurProducts/OurProduct";
import { useMyContext } from "../../utils/ContextApi/Context";

const Index = () => {
  useEffect(() => {
    document.body?.classList.add("remove-body-padding");
    document.getElementById("header")?.classList.add("header-bg-blue");
    return () => {
      document.body.classList.remove("remove-body-padding");
      document.getElementById("header")?.classList.remove("header-bg-blue");
    };
  }, []);
  const hostname = window.location.hostname;

  const { values, handleChange } = useMyContext();
  const [proProductVal, setProProductVal] = useState("");
  const [otherProductVal, setOtherProductVal] = useState("");
  const [currentCurrency, setCurrencyVal] = useState("Pound");

  const handleInputChange = (e) => {
    handleChange(e.target.value);
  };

  const setProductId = (e) => {
    setProProductVal(e);
    setOtherProductVal("");
  };

  const setOtherProductId = (e) => {
    setOtherProductVal(e);
    setProProductVal("");
  };

  const setcurrentCurrency = (e) => {
    setCurrencyVal(e);
  };

  return (
    <>
      <PlansBanner />

      {hostname === "www.novotrack.co.uk" ? (
        <OurProduct
          setProductId={(e) => setProductId(e)}
          proProductVal={proProductVal}
          setcurrentCurrency={(e) => setcurrentCurrency(e)}
        />
      ) : (
        ""
      )}

      <Product
        values={values}
        onChange={handleInputChange}
        setOtherProductId={(e) => setOtherProductId(e)}
        setcurrentCurrency={(e) => setcurrentCurrency(e)}
        otherProductVal={otherProductVal}
      />

      {hostname === "www.novotrack.co.uk" && proProductVal !== "" ? (
        <PlansDetail
          values={values}
          onChange={handleInputChange}
          proProductVal={proProductVal}
          currentCurrency={currentCurrency}
        />
      ) : (
        ""
      )}
      {hostname === "www.novotrack.co.uk" && otherProductVal !== "" ? (
        <ServiceCoverage
          otherProductVal={otherProductVal}
          currentCurrency={currentCurrency}
        />
      ) : hostname !== "www.novotrack.co.uk" ? (
        <ServiceCoverage
          otherProductVal={otherProductVal}
          currentCurrency={currentCurrency}
        />
      ) : (
        ""
      )}
      <JoinUs />
    </>
  );
};

export default Index;
