import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import "./footer.scss";
import { useTranslation } from "react-i18next";
import { UpArrowIcon } from "../../assets/Icons";
const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const footer = t("footer", { returnObjects: true });
  return (
    <>
      <div className="container">
        <footer>
          <div className="footer-wrapper">
            <div className="footer-logo-wrapper">
              <div className="footer-logo">
                {" "}
                <img src={Logo} alt="NovoTrack" />
              </div>
              <div className="legal-info">
                2024 &copy; {footer.allRightsReserved}
              </div>
             
            </div>
            <div className="footer-nav-wrapper">
              <div className="footer-nav-wrap">
                <h3 className="footer-nav-title">{footer.company}</h3>
                <ul className="footer-nav-list">
                  <li>
                    <span onClick={() => navigate("/products")}>
                      {footer.features}
                    </span>
                  </li>
                  <li>
                    <span onClick={() => navigate("/plans")}>
                      {footer.pricing}
                    </span>
                  </li>
                  <li>
                    <span onClick={() => navigate("/")}>{footer.aboutUs}</span>
                  </li>
                  <li>
                    <span onClick={() => navigate("/contactus")}>
                      {footer.contact}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="footer-nav-wrap">
                <h3 className="footer-nav-title">{footer.contact}</h3>
                <ul className="footer-nav-list">
                  <li>
                    <Link to="https://www.instagram.com/" target="_blank">
                      {footer.instagram}
                    </Link>
                  </li>
                  <li>
                    <Link to="https://twitter.com/" target="_blank">
                      {footer.x}
                    </Link>
                  </li>
                  <li>
                    <Link to="http://facbook.com/" target="_blank">
                      {footer.facebook}
                    </Link>
                  </li>
                  <li>
                    <Link to="https://linkedin.com/" target="_blank">
                      {footer.linkedin}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div> 

      <div className="scrolltop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
             <UpArrowIcon/>
            </div>
    </>
  );
};

export default Footer;
