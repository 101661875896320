import React from 'react'
import PublicLayout from '../layouts/Public'
import PaymentLayout from '../layouts/Payment'
import AuthLayout from '../layouts/Auth'
import Home from '../containers/Home/'
import Products from '../containers/Products'
import Plans from '../containers/Plans'
import Shop from '../containers/Shop'
import ContactUs from '../containers/Contactus/Contactus'
import Login from '../components/Auth/Login/Login'
import SignUp from '../components/Auth/SignUp/SignUp'
import Payment from '../containers/Payment/Payment'
import ProductDetail from '../containers/ProductDetail/ProductDetail'
import OurApp from '../containers/OurApp/'
import ErrorPage from "../containers/ErrorPage/ErrorPage"

const PublicRoutes= [
    {
        path: '/',
        exact: true,
        layout: PublicLayout,
        component: <Home />
    },
    {
        path: '/products',
        exact: true,
        layout: PublicLayout,
        component: <Products/>
    },    
    {
        path: '/product/:pid',
        exact: true,
        layout: PublicLayout,
        component: <ProductDetail/>
    },
    {
        path: '/plans',
        exact: true,
        layout: PublicLayout,
        component: <Plans/>
    },
    {
        path: '/shop',
        exact: true,
        layout: PublicLayout,
        component: <Shop/>
    },
    {
        path: '/contactus',
        exact: true,
        layout: PublicLayout,
        component: <ContactUs/>
    },
    {
        path: '/login',
        exact: true,
        layout: AuthLayout,
        component: <Login/>
    },
    {
        path: '/signup',
        exact: true,
        layout: AuthLayout,
        component: <SignUp/>
    },
    {
        path: '/payment',
        exact: true,
        layout: PaymentLayout,
        component: <Payment/>
    },
    {
        path: '/ourapp',
        exact: true,
        layout: PublicLayout,
        component: <OurApp/>
    },
    {
        path: '*',
        exact: true,
        layout: PublicLayout,
        component: <ErrorPage/>
    }

]

export default PublicRoutes