import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { DownArrow, Hamburger } from "../../assets/Icons";
import Logo from "../../assets/images/logo.svg";
import "./header.scss";
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
//import Form from "react-bootstrap/Form";

import "../../flags.css";
//import { useMyContext } from '../../utils/ContextApi/Context';

const Header = () => {
  //const { values, handleChange } = useMyContext();
  let navigate = useNavigate();
  const location = useLocation();
  const [isNavOpen, setIsNavOpen] = useState(false);
 // const [value, setValue] = useState("GBP");
  const handleHamburger = () => {
    setIsNavOpen(!isNavOpen);
  };
  const handleNavigate = (path) => {
    navigate(path);
    setIsNavOpen(false);
  };
  const { t, i18n } = useTranslation();
  const menu = t("menu", { returnObjects: true });
  // eslint-disable-next-line no-unused-vars
  const [currentlanguage, setCurrentlanguage] = useState(i18n.language);

  const [select, setSelect] = useState("GB");
  //const currentPath = window.location.pathname;
  useEffect(() => {
    if (currentlanguage === "en") {
      setSelect("GB");
    } else if (currentlanguage === "ka-GE") {
      setSelect("GE");
    } else if (currentlanguage === "zh-HK") {
      setSelect("CN");
    } else if (currentlanguage === "de") {
      setSelect("DE");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLanguage = (code) => {
    let lang = "en";
    if (code === "GB") {
      lang = "en";
    } else if (code === "GE") {
      lang = "ka-GE";
    } else if (code === "CN") {
      lang = "zh-HK";
    } else if (code === "DE") {
      lang = "de";
    }
    setSelect(code);
    i18n.changeLanguage(lang);
  };
  // const handleChangeCurrency = (event) => {
   
  //   setValue(event.target.value)

  //   handleChange("newVAl", (event.target.value));
  //   handleChange("oldVal", (values.newVAl) ? values.newVAl : "GBP");


  // };
  return (
    <>
      <header id="header">
        <div className="container">
          <div className="header-wrapper">
            <div className="logo" onClick={() => navigate("/")}>
              <img src={Logo} alt="NovoTrack" />
            </div>
            <div className="header-nav-wrap">
              <ul className={`nav ${isNavOpen ? "open" : ""}`}>
                <li
                  onClick={() => handleNavigate("/")}
                  className={useMatch("/") ? "active" : ""}
                >
                  {menu.home}
                </li>
                <li
                  onClick={() => handleNavigate("/products")}
                  className={
                    location.pathname.startsWith("/products") ? "active" : ""
                  }
                >
                  {menu.products}
                </li>
                <li
                  onClick={() => handleNavigate("/plans")}
                  className={
                    location.pathname.startsWith("/plans") ? "active" : ""
                  }
                >
                  {menu.plans}
                </li>
                <li
                  onClick={() => handleNavigate("/ourapp")}
                  className={
                    location.pathname.startsWith("/ourapp") ? "active" : ""
                  }
                >
                  {menu.ourapp}
                </li>
                <li
                  onClick={() => handleNavigate("/shop")}
                  className={
                    location.pathname.startsWith("/shop") ? "active" : ""
                  }
                >
                  <Dropdown>
                    <Dropdown.Toggle id="service-nav">
                      {menu.order} <DownArrow />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="">Order 1</Dropdown.Item>
                      <Dropdown.Item href="">Order 2</Dropdown.Item>
                      <Dropdown.Item href="">Order 3</Dropdown.Item>
                      <Dropdown.Item href="">Order 4</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li
                  onClick={() => handleNavigate("/contactus")}
                  className={
                    location.pathname.startsWith("/contactus") ? "active" : ""
                  }
                >
                  {menu.contactUs}
                </li>
                <li className="login-btn-wrap">
                  {" "}
                  <Button
                    variant="outline-secondary"
                    onClick={() => handleNavigate("/login")}
                  >
                    {menu.login}
                  </Button>
                </li>
              </ul>
              {/* {currentPath === "/plans" ? (
                <div className="currency-wrap">
                  <Form.Select value={value} onChange={handleChangeCurrency}>
                    <option value="GBP">£</option>
                    <option value="USD">$</option>
                    <option value="EUR">€</option>
                  </Form.Select>
                </div>
              ) : (
                ""
              )} */}

              <div>
                <ReactFlagsSelect
                  selected={select}
                  onSelect={changeLanguage}
                  countries={["GB", "CN", "GE", "DE"]}
                  customLabels={{
                    GB: "English",
                    CN: "中文",
                    GE: "ქართული",
                    DE: "Deutsch",
                  }}
                />
              </div>
              <div className="hamburger" onClick={() => handleHamburger()}>
                <Hamburger />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
