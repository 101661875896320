import React, {useState} from 'react'
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import LanfFlag from '../../../assets/images/lang-flag.png'
import Button from 'react-bootstrap/Button';
import './Login.scss'
import { EyeIcon, TickMark } from '../../../assets/Icons';
import { useTranslation } from "react-i18next";
const Login = () => {
    const { t } = useTranslation();
    const login = t("login", { returnObjects: true });
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(true);

    const handleCheckboxChange = (event) => {
      setIsChecked(event.target.checked);
    };
    return (
        <>
            <div className='auth-container'>
                <div className='auth-wrapper'>
                    <div className='auth-top'>
                        <div className='auth-title-wrap'>
                            <div className='auth-title'>{login.welcomeBack}</div>
                            <div className='auth-decription'>{login.loginContinue}</div>
                        </div>
                        <div className='lang-wrapper-main'>
                            <div className='lang-wrap'>
                                <div className='flag-wraper'><img src={LanfFlag} alt='Flag' /></div>
                                <div className='lang-name-wrap'>English</div>
                            </div>
                        </div>
                    </div>
                    <Form className='login-form-wrap'>
                        <div className='login-input-container'>
                            <div className='form-row'>
                                <Form.Label>{login.userName}</Form.Label>
                                <Form.Control type="text" placeholder="Enter your Username" />
                            </div>
                            <div className='form-row'>
                                <Form.Label>{login.password}</Form.Label>
                                <Form.Control type="password" placeholder="Enter your Password" />
                                <div className='icon-wrap'><EyeIcon/></div>
                            </div>
                        </div>
                        <div className='remember-wrap'>
                            <div className="custom-checkbox">
                                <input type="checkbox" id="remember"checked={isChecked} onChange={handleCheckboxChange}/>
                                <i><TickMark /></i>
                                <label htmlFor="remember">{login.rememberMe}</label>
                            </div>
                            <div className='forgot-wrap'>
                            {login.forgetPassword}
                            </div>
                        </div>
                        <div className='btn-wrap'>
                            <Button type='submit' variant='primary'>{login.login}</Button>
                        </div>
                        <div className='have-an-account-wrapper'>{login.text1} <span onClick={() => navigate('/signup')}>{login.signUp}</span></div>
                    </Form>
                </div>

            </div>
        </>
    )
}

export default Login