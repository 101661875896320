import React from 'react'

const PaymentLayout = ({ children }) => {
  return (
    <>
    { children }
    </>
  )
}

export default PaymentLayout