import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: process.env.REACT_APP_Lang,   //default language
        fallbackLng:"en", //when specified language translations not present 
        //then fallbacklang translations loaded.
        debug: true,
        backend: {
            /* translation file path */
            loadPath: "/assets/i18n/{{ns}}/{{lng}}.json",
        },
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ",",
        },
        react: {
            wait: true,
        },
    });

    if(window.location.hostname==='www.novotrack.co.uk'){
        i18n.changeLanguage('en');
      }
     
    if(window.location.hostname==='www.novotrack.ge'){
        i18n.changeLanguage('ka-GE');
    }
      
export default i18n;