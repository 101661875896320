import React, { createContext, useState } from 'react';

const MyContext = createContext();

export const MyProvider = ({ children }) => {
  const [values, setValues] = useState({
    oldVal: "GBP",
    newVAl: ''
  });

  const handleChange = (name, newValue) => {
   setValues(prevState => ({
    ...prevState,
    [name]: newValue
  }));
  };

  return (
    <MyContext.Provider value={{ values, handleChange }}>
      {children}
    </MyContext.Provider>
  );
};

export const useMyContext = () => React.useContext(MyContext);