import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./ChooseVehicleType.scss";
import { TickMark } from "../../../assets/Icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ChooseVehicleType = (props) => {
  const navigate = useNavigate();
  // const newVal = props.values.newVAl;
  // const oldVal = props.values.oldVal;
  useEffect(() => {
    if (props.otherProductVal === "") {
      setIsChecked("");
    }
  }, [props.otherProductVal]);
  //let changeRate;
  let price1;
  let price2;
  let price3;
  let price4;
  let price5;
  let currencySign;

  // if (oldVal === "GBP" && (newVal === "" || newVal === undefined)) {
  //   changeRate = 1;
  //   // currencySign = "£";
  // }

  // if (oldVal === "GBP" && newVal === "USD") {
  //   changeRate = 1.25;
  //   // currencySign = "$";
  // } else if (oldVal === "GBP" && newVal === "EUR") {
  //   changeRate = 1.17;
  //   // currencySign = "€";
  // } else if (oldVal === "USD" && newVal === "GBP") {
  //   changeRate = 0.8;
  //   // currencySign = "£";
  // } else if (oldVal === "USD" && newVal === "EUR") {
  //   changeRate = 0.93;
  //   // currencySign = "€";
  // } else if (oldVal === "EUR" && newVal === "GBP") {
  //   changeRate = 0.86;
  //   // currencySign = "£";
  // } else if (oldVal === "EUR" && newVal === "USD") {
  //   changeRate = 1.07;
  //   // currencySign = "$";
  // }
  const hostname = window.location.hostname;

  const { t } = useTranslation();
  const vehicleType = t("vehicleType", { returnObjects: true });
  let checkedValue = hostname === "www.novotrack.co.uk" ? "" : "";
  const [isChecked, setIsChecked] = useState(checkedValue);

  const handleSelectProduct = (productid) => {
    setIsChecked(productid);
    props.setOtherProductId(productid);
  };

  const [isCurrenyVChecked, setIsCurrenyVChecked] = useState("Pound");

  const handleSelectVCurreny = (curreny) => {
    setIsCurrenyVChecked(curreny);
    props.setcurrentCurrency(curreny);
  };

  if (isCurrenyVChecked === "Pound") {
    currencySign = "£";
    price1 = 5.99;
    price2 = 5.99;
    price3 = 5.99;
    price4 = 5.99;
    price5 = 4.99;
  } else if (isCurrenyVChecked === "Dollar") {
    currencySign = "$";
    price1 = 6.49;
    price2 = 6.49;
    price3 = 6.49;
    price4 = 6.49;
    price5 = 5.49;
  } else if (isCurrenyVChecked === "Euro") {
    currencySign = "€";
    price1 = 5.99;
    price2 = 5.99;
    price3 = 5.99;
    price4 = 5.99;
    price5 = 4.99;
  }

  return (
    <>
      {hostname === "www.novotrack.co.uk" ? (
        <div className="choose-our-product-container bg-transparent pt-0">
          <div className="container">
            <div className="choose-our-product-wrap">
              <div className="choose-product-wrap">
                <div className="choose-product-title">
                  {vehicleType.chooseProduct}
                </div>
                <div className="toggle-btn-wrapper for_shop_plan bg-transparent ">
                  {/* <div className="switches-container switchMoney">
                    <input
                      type="radio"
                      id="switchMonthly"
                      name="switchPlan"
                      value="Monthly"
                      defaultChecked
                    />
                    <input
                      type="radio"
                      id="switchQuarterly"
                      name="switchPlan"
                      value="Quarterly"
                    />
                    <input
                      type="radio"
                      id="switchAnnual"
                      name="switchPlan"
                      value="Annual"
                    />
                    <label htmlFor="switchMonthly">Monthly</label>
                    <label htmlFor="switchQuarterly">Quarterly</label>
                    <label htmlFor="switchAnnual">Annual</label>
                    <div className="switch-wrapper">
                      <div className="switch">
                        <div>Monthly</div>
                        <div>Quarterly</div>
                        <div>Annual</div>
                      </div>
                    </div>
                  </div> */}
                  {/* <span></span> */}
                </div>
                <div className="product-slider">
                  <Swiper
                    spaceBetween={24}
                    slidesPerView={5}
                    breakpoints={{
                      300: {
                        slidesPerView: 1.5,
                      },
                      768: {
                        slidesPerView: 3,
                      },

                      1199: {
                        slidesPerView: 5,
                      },
                    }}
                  >
                    <SwiperSlide>
                      <div
                        className={
                          isChecked === "product1"
                            ? "active product-box"
                            : "product-box"
                        }
                      >
                        <div className="choose-product-top">
                          <div className="product-tag">NTSL2</div>
                          <div className="product-select-wrap">
                            <input
                              id="product1"
                              type="checkbox"
                              value="product1"
                              checked={isChecked === "product1"}
                              onChange={() => handleSelectProduct("product1")}
                            />
                            <label
                              htmlFor="product1"
                              className="custom-checkbox"
                            >
                              <TickMark />
                            </label>
                          </div>
                        </div>
                        <div className="choose-product-description">
                          {vehicleType.text1}
                        </div>
                        <div className="choose-product-footer">
                          <div className="product-price">
                            {currencySign}
                            {price1}
                            {/* {newVal === "" || newVal === undefined
                              ? 1000
                              : 1000 * changeRate} */}
                          </div>
                          <div
                            className="learn-more"
                            onClick={() => navigate("/product/NTSL2")}
                          >
                            {vehicleType.learnMore}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className={
                          isChecked === "product2"
                            ? "active product-box"
                            : "product-box"
                        }
                      >
                        <div className="choose-product-top">
                          <div className="product-tag">NTSC2</div>
                          <div className="product-select-wrap">
                            <input
                              id="product2"
                              type="checkbox"
                              value="product2"
                              checked={isChecked === "product2"}
                              onChange={() => handleSelectProduct("product2")}
                            />
                            <label
                              htmlFor="product2"
                              className="custom-checkbox"
                            >
                              <TickMark />
                            </label>
                          </div>
                        </div>
                        <div className="choose-product-description">
                          {vehicleType.text2}
                        </div>
                        <div className="choose-product-footer">
                          <div className="product-price">
                            {currencySign}
                            {price2}
                            {/* {newVal === "" || newVal === undefined
                              ? 1000
                              : 1000 * changeRate} */}
                          </div>
                          <div
                            className="learn-more"
                            onClick={() => navigate("/product/NTSC2")}
                          >
                            {vehicleType.learnMore}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className={
                          isChecked === "product3"
                            ? "active product-box"
                            : "product-box"
                        }
                      >
                        <div className="choose-product-top">
                          <div className="choose-product-top">
                            <div className="product-tag">NTSCM3</div>
                            <div className="product-select-wrap">
                              <input
                                id="product3"
                                type="checkbox"
                                value="product3"
                                checked={isChecked === "product3"}
                                onChange={() => handleSelectProduct("product3")}
                              />
                              <label
                                htmlFor="product3"
                                className="custom-checkbox"
                              >
                                <TickMark />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="choose-product-description">
                          {vehicleType.text3}
                        </div>
                        <div className="choose-product-footer">
                          <div className="product-price">
                            {currencySign}
                            {price3}
                            {/* {newVal === "" || newVal === undefined
                              ? 1000
                              : 1000 * changeRate} */}
                          </div>
                          <div
                            className="learn-more"
                            onClick={() => navigate("/product/NTSCM3")}
                          >
                            {vehicleType.learnMore}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className={
                          isChecked === "product4"
                            ? "active product-box"
                            : "product-box"
                        }
                      >
                        <div className="choose-product-top">
                          <div className="choose-product-top">
                            <div className="product-tag">NTDS2</div>
                            <div className="product-select-wrap">
                              <input
                                id="product4"
                                type="checkbox"
                                value="product4"
                                checked={isChecked === "product4"}
                                onChange={() => handleSelectProduct("product4")}
                              />
                              <label
                                htmlFor="product4"
                                className="custom-checkbox"
                              >
                                <TickMark />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="choose-product-description">
                          {vehicleType.text4}
                        </div>
                        <div className="choose-product-footer">
                          <div className="product-price">
                            {currencySign}
                            {price4}
                            {/* {newVal === "" || newVal === undefined
                              ? 1000
                              : 1000 * changeRate} */}
                          </div>
                          <div
                            className="learn-more"
                            onClick={() => navigate("/product/NTDS2")}
                          >
                            {vehicleType.learnMore}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className={
                          isChecked === "product5"
                            ? "active product-box"
                            : "product-box"
                        }
                      >
                        <div className="choose-product-top">
                          <div className="choose-product-top">
                            <div className="product-tag">NTSR3</div>
                            <div className="product-select-wrap">
                              <input
                                id="product5"
                                type="checkbox"
                                value="product5"
                                checked={isChecked === "product5"}
                                onChange={() => handleSelectProduct("product5")}
                              />
                              <label
                                htmlFor="product5"
                                className="custom-checkbox"
                              >
                                <TickMark />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="choose-product-description">
                          {vehicleType.text5}
                        </div>
                        <div className="choose-product-footer">
                          <div className="product-price">
                            {currencySign}
                            {price5}
                            {/* {newVal === "" || newVal === undefined
                              ? 1000
                              : 1000 * changeRate} */}
                          </div>
                          <div
                            className="learn-more"
                            onClick={() => navigate("/product/NTSR3")}
                          >
                            {vehicleType.learnMore}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
            {/* <div className="hr-line"></div> */}
          </div>
        </div>
      ) : (
        <div className="choose-our-product-container ">
          <div className="container">
            <div className="choose-our-product-wrap">
              <div className="choose-product-wrap">
                <div className="choose-product-title">
                  {vehicleType.chooseProduct}
                </div>
                <div className="toggle-btn-wrapper for_shop_plan">
                  {/* <div className="switches-container switchMoney">
                    <input
                      type="radio"
                      id="switchMonthly"
                      name="switchPlan"
                      value="Monthly"
                      defaultChecked
                    />
                    <input
                      type="radio"
                      id="switchQuarterly"
                      name="switchPlan"
                      value="Quarterly"
                    />
                    <input
                      type="radio"
                      id="switchAnnual"
                      name="switchPlan"
                      value="Annual"
                    />
                    <label htmlFor="switchMonthly">Monthly</label>
                    <label htmlFor="switchQuarterly">Quarterly</label>
                    <label htmlFor="switchAnnual">Annual</label>
                    <div className="switch-wrapper">
                      <div className="switch">
                        <div>Monthly</div>
                        <div>Quarterly</div>
                        <div>Annual</div>
                      </div>
                    </div>
                  </div> */}
                  {/* <span></span> */}
                  {hostname !== "www.novotrack.co.uk" ? (
                    <div className="switches-container switchMoney">
                      <input
                        type="radio"
                        id="switchPound"
                        name="switchMoney"
                        value="Pound"
                        checked={isCurrenyVChecked === "Pound"}
                        onChange={() => handleSelectVCurreny("Pound")}
                      />
                      <input
                        type="radio"
                        id="switchDollar"
                        name="switchMoney"
                        value="Dollar"
                        checked={isCurrenyVChecked === "Dollar"}
                        onChange={() => handleSelectVCurreny("Dollar")}
                      />
                      <input
                        type="radio"
                        id="switchEuro"
                        name="switchMoney"
                        value="Euro"
                        checked={isCurrenyVChecked === "Euro"}
                        onChange={() => handleSelectVCurreny("Euro")}
                      />
                      <label htmlFor="switchPound"> £ Pound</label>
                      <label htmlFor="switchDollar">$ Dollar</label>
                      <label htmlFor="switchEuro">€ Euro</label>
                      <div className="switch-wrapper">
                        <div className="switch">
                          <div>£ Pound</div>
                          <div>$ Dollar</div>
                          <div>€ Euro</div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="product-slider">
                  <Swiper
                    spaceBetween={24}
                    slidesPerView={5}
                    breakpoints={{
                      300: {
                        slidesPerView: 1.5,
                      },
                      768: {
                        slidesPerView: 3,
                      },

                      1199: {
                        slidesPerView: 5,
                      },
                    }}
                  >
                    <SwiperSlide>
                      <div
                        className={
                          isChecked === "product1"
                            ? "active product-box"
                            : "product-box"
                        }
                      >
                        <div className="choose-product-top">
                          <div className="product-tag">NTSL2</div>
                          <div className="product-select-wrap">
                            <input
                              id="product1"
                              type="checkbox"
                              value="product1"
                              checked={isChecked === "product1"}
                              onChange={() => handleSelectProduct("product1")}
                            />
                            <label
                              htmlFor="product1"
                              className="custom-checkbox"
                            >
                              <TickMark />
                            </label>
                          </div>
                        </div>
                        <div className="choose-product-description">
                          {vehicleType.text1}
                        </div>
                        <div className="choose-product-footer">
                          <div className="product-price">
                            {currencySign}
                            {price1}
                            {/* {newVal === "" || newVal === undefined
                              ? 1000
                              : 1000 * changeRate} */}
                          </div>
                          <div
                            className="learn-more"
                            onClick={() => navigate("/product/NTSL2")}
                          >
                            {vehicleType.learnMore}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className={
                          isChecked === "product2"
                            ? "active product-box"
                            : "product-box"
                        }
                      >
                        <div className="choose-product-top">
                          <div className="product-tag">NTSC2</div>
                          <div className="product-select-wrap">
                            <input
                              id="product2"
                              type="checkbox"
                              value="product2"
                              checked={isChecked === "product2"}
                              onChange={() => handleSelectProduct("product2")}
                            />
                            <label
                              htmlFor="product2"
                              className="custom-checkbox"
                            >
                              <TickMark />
                            </label>
                          </div>
                        </div>
                        <div className="choose-product-description">
                          {vehicleType.text2}
                        </div>
                        <div className="choose-product-footer">
                          <div className="product-price">
                            {currencySign}
                            {price2}
                            {/* {newVal === "" || newVal === undefined
                              ? 1000
                              : 1000 * changeRate} */}
                          </div>
                          <div
                            className="learn-more"
                            onClick={() => navigate("/product/NTSC2")}
                          >
                            {vehicleType.learnMore}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className={
                          isChecked === "product3"
                            ? "active product-box"
                            : "product-box"
                        }
                      >
                        <div className="choose-product-top">
                          <div className="choose-product-top">
                            <div className="product-tag">NTSCM3</div>
                            <div className="product-select-wrap">
                              <input
                                id="product3"
                                type="checkbox"
                                value="product3"
                                checked={isChecked === "product3"}
                                onChange={() => handleSelectProduct("product3")}
                              />
                              <label
                                htmlFor="product3"
                                className="custom-checkbox"
                              >
                                <TickMark />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="choose-product-description">
                          {vehicleType.text3}
                        </div>
                        <div className="choose-product-footer">
                          <div className="product-price">
                            {currencySign}
                            {price3}
                            {/* {newVal === "" || newVal === undefined
                              ? 1000
                              : 1000 * changeRate} */}
                          </div>
                          <div
                            className="learn-more"
                            onClick={() => navigate("/product/NTSCM3")}
                          >
                            {vehicleType.learnMore}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className={
                          isChecked === "product4"
                            ? "active product-box"
                            : "product-box"
                        }
                      >
                        <div className="choose-product-top">
                          <div className="choose-product-top">
                            <div className="product-tag">NTDS2</div>
                            <div className="product-select-wrap">
                              <input
                                id="product4"
                                type="checkbox"
                                value="product4"
                                checked={isChecked === "product4"}
                                onChange={() => handleSelectProduct("product4")}
                              />
                              <label
                                htmlFor="product4"
                                className="custom-checkbox"
                              >
                                <TickMark />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="choose-product-description">
                          {vehicleType.text4}
                        </div>
                        <div className="choose-product-footer">
                          <div className="product-price">
                            {currencySign}
                            {price4}
                            {/* {newVal === "" || newVal === undefined
                              ? 1000
                              : 1000 * changeRate} */}
                          </div>
                          <div
                            className="learn-more"
                            onClick={() => navigate("/product/NTDS2")}
                          >
                            {vehicleType.learnMore}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className={
                          isChecked === "product5"
                            ? "active product-box"
                            : "product-box"
                        }
                      >
                        <div className="choose-product-top">
                          <div className="choose-product-top">
                            <div className="product-tag">NTSR3</div>
                            <div className="product-select-wrap">
                              <input
                                id="product5"
                                type="checkbox"
                                value="product5"
                                checked={isChecked === "product5"}
                                onChange={() => handleSelectProduct("product5")}
                              />
                              <label
                                htmlFor="product5"
                                className="custom-checkbox"
                              >
                                <TickMark />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="choose-product-description">
                          {vehicleType.text5}
                        </div>
                        <div className="choose-product-footer">
                          <div className="product-price">
                            {currencySign}
                            {price5}
                            {/* {newVal === "" || newVal === undefined
                              ? 1000
                              : 1000 * changeRate} */}
                          </div>
                          <div
                            className="learn-more"
                            onClick={() => navigate("/product/NTSR3")}
                          >
                            {vehicleType.learnMore}
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
            {/* <div className="hr-line"></div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default ChooseVehicleType;
