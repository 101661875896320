import React, { useState } from "react";
import { Modal } from 'react-bootstrap';
// import HardwiredTracker from "../../../assets/images/products/hardwired-tracker.png";
import NTSL2 from "../../../assets/images/products/NTSL2/Main-small.png";
import NTDS2 from "../../../assets/images/products/NTDS2/Main-small.png";
import NTSC2 from "../../../assets/images/products/NTSC2/Main-small.png";
import NTSCM3 from "../../../assets/images/products/NTSCM3/Main-small.png";
import NTSR3 from "../../../assets/images/products/NTSR3/Main-small.png";
import "./ProductsList.scss";
import { TickMark } from "../../../assets/Icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const ProductsList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const product = t("productList", { returnObjects: true });
  const compare = t("compare", { returnObjects: true });
  const hostname = window.location.hostname;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {hostname === "www.novotrack.co.uk" ? (
        <>
          <div className="product-list-wrapper check-products-wrapper">
            <div className="container">
              <div className="text-center mb-3">
              <button type="button" className="btn btn-light text-center" onClick={handleShow}>
                {compare.compareAll}
              </button>
              </div>
              <div className="product-title-wrap">
                {hostname === "www.novotrack.co.uk"
                  ? product.checkOtherProduct
                  : product.ourProducts}
              </div>
              <div className="product-box-wrapper">
                <div className="product-box">
                  <div className="product-tag">
                    <span>NTSL2</span>
                  </div>
                  <div className="product-img-wrap">
                    <img src={NTSL2} alt="NTSL2" />
                  </div>
                  <div className="product-detail-wrap">
                    <div className="product-detail-title-wrap">
                      <div className="product-detail-title">
                        {product.secureLink}
                      </div>
                      <div className="product-detail-subtitle">
                        {product.text1}
                      </div>
                    </div>
                    <div className="product-list-wrap">
                      <div className="product-list-row">
                        <div className="product-icon-wrap">
                          <TickMark />
                        </div>
                        <div className="product-list">{product.text2}</div>
                      </div>
                      <div className="product-list-row">
                        <div className="product-icon-wrap">
                          <TickMark />
                        </div>
                        <div className="product-list">{product.text3}</div>
                      </div>
                    </div>
                  </div>
                  <div className="learn-more">
                    <span onClick={() => navigate("/product/NTSL2")}>
                      {product.learnMore}
                    </span>
                  </div>
                </div>
                <div className="product-box">
                  <div className="product-tag">
                    <span>NTSC2</span>
                  </div>
                  <div className="product-img-wrap">
                    <img src={NTSC2} alt="NTSC2" />
                  </div>
                  <div className="product-detail-wrap">
                    <div className="product-detail-title-wrap">
                      <div className="product-detail-title">{product.text4}</div>
                      <div className="product-detail-subtitle">
                        {product.text5}
                      </div>
                    </div>
                    <div className="product-list-wrap">
                      <div className="product-list-row">
                        <div className="product-icon-wrap">
                          <TickMark />
                        </div>
                        <div className="product-list">{product.text6}</div>
                      </div>
                      <div className="product-list-row">
                        <div className="product-icon-wrap">
                          <TickMark />
                        </div>
                        <div className="product-list">{product.text7}</div>
                      </div>
                    </div>
                  </div>
                  <div className="learn-more">
                    <span onClick={() => navigate("/product/NTSC2")}>
                      {product.learnMore}
                    </span>
                  </div>
                </div>
                <div className="product-box">
                  <div className="product-tag">
                    <span>NTSCM3</span>
                  </div>
                  <div className="product-img-wrap">
                    <img src={NTSCM3} alt="NTSCM2" />
                  </div>
                  <div className="product-detail-wrap">
                    <div className="product-detail-title-wrap">
                      <div className="product-detail-title">{product.text8}</div>
                      <div className="product-detail-subtitle">
                        {product.text9}
                      </div>
                    </div>
                    <div className="product-list-wrap">
                      <div className="product-list-row">
                        <div className="product-icon-wrap">
                          <TickMark />
                        </div>
                        <div className="product-list">{product.text10}</div>
                      </div>
                      <div className="product-list-row">
                        <div className="product-icon-wrap">
                          <TickMark />
                        </div>
                        <div className="product-list">{product.text11}</div>
                      </div>
                      <div className="product-list-row">
                        <div className="product-icon-wrap">
                          <TickMark />
                        </div>
                        <div className="product-list">{product.text12}</div>
                      </div>
                    </div>
                  </div>
                  <div className="learn-more">
                    <span onClick={() => navigate("/product/NTSCM3")}>
                      {product.learnMore}
                    </span>
                  </div>
                </div>
                <div className="product-box">
                  <div className="product-tag">
                    <span>NTDS2 </span>
                  </div>
                  <div className="product-img-wrap">
                    <img src={NTDS2} alt="NTDS2 " />
                  </div>
                  <div className="product-detail-wrap">
                    <div className="product-detail-title-wrap">
                      <div className="product-detail-title">{product.text13}</div>
                      <div className="product-detail-subtitle">
                        {product.text14}
                      </div>
                    </div>
                    <div className="product-list-wrap">
                      <div className="product-list-row">
                        <div className="product-icon-wrap">
                          <TickMark />
                        </div>
                        <div className="product-list">{product.text15}</div>
                      </div>
                      <div className="product-list-row">
                        <div className="product-icon-wrap">
                          <TickMark />
                        </div>
                        <div className="product-list">{product.text16}</div>
                      </div>
                    </div>
                  </div>
                  <div className="learn-more">
                    <span onClick={() => navigate("/product/NTDS2")}>
                      {product.learnMore}
                    </span>
                  </div>
                </div>
                <div className="product-box">
                  <div className="product-tag">
                    <span>NTSR3 </span>
                  </div>
                  <div className="product-img-wrap">
                    <img src={NTSR3} alt="NTSR3 " />
                  </div>
                  <div className="product-detail-wrap">
                    <div className="product-detail-title-wrap">
                      <div className="product-detail-title">{product.text17}</div>
                      <div className="product-detail-subtitle">
                        {product.text18}
                      </div>
                    </div>
                    <div className="product-list-wrap">
                      <div className="product-list-row">
                        <div className="product-icon-wrap">
                          <TickMark />
                        </div>
                        <div className="product-list">{product.text19}</div>
                      </div>
                      <div className="product-list-row">
                        <div className="product-icon-wrap">
                          <TickMark />
                        </div>
                        <div className="product-list">{product.text20}</div>
                      </div>
                    </div>
                  </div>
                  <div className="learn-more">
                    <span onClick={() => navigate("/product/NTSR3")}>
                      {product.learnMore}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal show={show} onHide={handleClose} size="lg"
            aria-labelledby="contained-modal-title-vcenter" dialogClassName="modal-90w"
            centered>
            <Modal.Body className="modalbody" closeButton>
              <div className="prod_recommended">
                <div className="prod_recommended_list">
                  <div className="prod_recommended_item">
                    <img src={NTSR3} alt="product name" className="item_img" />
                    <div className="item_name">{compare.sProducts.secureS7.productName}</div>
                    <div className="item_id item_price">
                      {compare.sProducts.secureS7.productId}
                      <p>{compare.sProducts.secureS7.head1}</p>
                    </div>
                    <div className="item_price">
                      {compare.sProducts.secureS7.price1}
                      <p>{compare.sProducts.secureS7.priceHead1}</p>
                    </div>
                    <div className="item_price">
                      {compare.sProducts.secureS7.price2}
                      <p>{compare.sProducts.secureS7.priceHead2}</p>
                    </div>
                    <div className="item_price">
                      {compare.sProducts.secureS7.price3}
                      <p>
                        {compare.sProducts.secureS7.description}
                      </p>
                    </div>
                    <div className="data_presence">
                      <span className="na">NA</span>
                    </div>
                    <div className="data_presence">
                      <span className="na">NA</span>
                    </div>
                    <div className="data_presence">
                      <span className="na">NA</span>
                    </div>
                    <div className="data_presence">
                      <span className="na">NA</span>
                    </div>
                    <div className="data_presence">
                      <p className="data_icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                        >
                          <path
                            d="M29.6598 18.3398L18.3398 29.6598"
                            stroke="#FF4032"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M29.6598 29.6598L18.3398 18.3398"
                            stroke="#FF4032"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M24 42V42C14.058 42 6 33.942 6 24V24C6 14.058 14.058 6 24 6V6C33.942 6 42 14.058 42 24V24C42 33.942 33.942 42 24 42Z"
                            stroke="#FF4032"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                      <p>{compare.sProducts.secureS7.keyFeature5}</p>
                    </div>
                    <div className="data_presence">
                      <p className="data_icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                        >
                          <path
                            d="M39.123 14.8867L24.011 29.9987L16.457 22.4427"
                            stroke="#0068FF"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M42 24C42 33.942 33.942 42 24 42C14.058 42 6 33.942 6 24C6 14.058 14.058 6 24 6C26.906 6 29.642 6.706 32.072 7.928"
                            stroke="#0068FF"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                      <p>{compare.sProducts.secureS7.keyFeature6}</p>
                    </div>

                  </div>
                  <div className="prod_recommended_item">
                    <img src={NTSR3} alt="product name" className="item_img" />
                    <div className="item_name">{compare.sProducts.proS5.productName}</div>
                    <div className="item_id item_price">
                      {compare.sProducts.proS5.productId}
                      <p>{compare.sProducts.proS5.head1}</p>
                    </div>
                    <div className="item_price">
                      {compare.sProducts.proS5.price1}
                      <p>{compare.sProducts.proS5.priceHead1}</p>
                    </div>
                    <div className="item_price">
                      {compare.sProducts.proS5.price2}
                      <p>{compare.sProducts.proS5.priceHead2}</p>
                    </div>
                    <div className="item_price">
                      {compare.sProducts.proS5.price3}
                      <p>
                        {compare.sProducts.proS5.description}
                      </p>
                    </div>
                    <div className="data_presence">
                      <p className="data_icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                        >
                          <path
                            d="M29.6598 18.3398L18.3398 29.6598"
                            stroke="#FF4032"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M29.6598 29.6598L18.3398 18.3398"
                            stroke="#FF4032"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M24 42V42C14.058 42 6 33.942 6 24V24C6 14.058 14.058 6 24 6V6C33.942 6 42 14.058 42 24V24C42 33.942 33.942 42 24 42Z"
                            stroke="#FF4032"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                      <p>{compare.sProducts.proS5.keyFeature1}</p>
                    </div>
                    <div className="data_presence">
                      <p className="data_icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                        >
                          <path
                            d="M29.6598 18.3398L18.3398 29.6598"
                            stroke="#FF4032"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M29.6598 29.6598L18.3398 18.3398"
                            stroke="#FF4032"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M24 42V42C14.058 42 6 33.942 6 24V24C6 14.058 14.058 6 24 6V6C33.942 6 42 14.058 42 24V24C42 33.942 33.942 42 24 42Z"
                            stroke="#FF4032"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                      <p>{compare.sProducts.proS5.keyFeature2}</p>
                    </div>
                    <div className="data_presence">
                      <p className="data_icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                        >
                          <path
                            d="M39.123 14.8867L24.011 29.9987L16.457 22.4427"
                            stroke="#0068FF"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M42 24C42 33.942 33.942 42 24 42C14.058 42 6 33.942 6 24C6 14.058 14.058 6 24 6C26.906 6 29.642 6.706 32.072 7.928"
                            stroke="#0068FF"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                      <p>{compare.sProducts.proS5.keyFeature3}</p>
                    </div>
                    <div className="data_presence">
                      <p className="data_icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                        >
                          <path
                            d="M39.123 14.8867L24.011 29.9987L16.457 22.4427"
                            stroke="#0068FF"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M42 24C42 33.942 33.942 42 24 42C14.058 42 6 33.942 6 24C6 14.058 14.058 6 24 6C26.906 6 29.642 6.706 32.072 7.928"
                            stroke="#0068FF"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                      <p>{compare.sProducts.proS5.keyFeature4}</p>
                    </div>
                    <div className="data_presence">
                      <p className="data_icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                        >
                          <path
                            d="M29.6598 18.3398L18.3398 29.6598"
                            stroke="#FF4032"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M29.6598 29.6598L18.3398 18.3398"
                            stroke="#FF4032"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M24 42V42C14.058 42 6 33.942 6 24V24C6 14.058 14.058 6 24 6V6C33.942 6 42 14.058 42 24V24C42 33.942 33.942 42 24 42Z"
                            stroke="#FF4032"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                      <p>{compare.sProducts.proS5.keyFeature5}</p>
                    </div>
                    <div className="data_presence">
                      <p className="data_icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                        >
                          <path
                            d="M39.123 14.8867L24.011 29.9987L16.457 22.4427"
                            stroke="#0068FF"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M42 24C42 33.942 33.942 42 24 42C14.058 42 6 33.942 6 24C6 14.058 14.058 6 24 6C26.906 6 29.642 6.706 32.072 7.928"
                            stroke="#0068FF"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                      <p>{compare.sProducts.proS5.keyFeature6}</p>
                    </div>

                  </div>
                  <div className="prod_recommended_item">
                    <img src={NTSR3} alt="product name" className="item_img" />
                    <div className="item_name">{compare.sProducts.eliteS5.productName}</div>
                    <div className="item_id item_price">
                      {compare.sProducts.eliteS5.productId}
                      <p>{compare.sProducts.eliteS5.head1}</p>
                    </div>
                    <div className="item_price">
                      {compare.sProducts.eliteS5.price1}
                      <p>{compare.sProducts.eliteS5.priceHead1}</p>
                    </div>
                    <div className="item_price">
                      {compare.sProducts.eliteS5.price2}
                      <p>{compare.sProducts.eliteS5.priceHead2}</p>
                    </div>
                    <div className="item_price">
                      {compare.sProducts.eliteS5.price3}
                      <p>
                        {compare.sProducts.eliteS5.description}
                      </p>
                    </div>
                    <div className="data_presence">
                      <p className="data_icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                        >
                          <path
                            d="M29.6598 18.3398L18.3398 29.6598"
                            stroke="#FF4032"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M29.6598 29.6598L18.3398 18.3398"
                            stroke="#FF4032"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M24 42V42C14.058 42 6 33.942 6 24V24C6 14.058 14.058 6 24 6V6C33.942 6 42 14.058 42 24V24C42 33.942 33.942 42 24 42Z"
                            stroke="#FF4032"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                      <p> {compare.sProducts.eliteS5.keyFeature1}</p>
                    </div>
                    <div className="data_presence">
                      <p className="data_icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                        >
                          <path
                            d="M29.6598 18.3398L18.3398 29.6598"
                            stroke="#FF4032"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M29.6598 29.6598L18.3398 18.3398"
                            stroke="#FF4032"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M24 42V42C14.058 42 6 33.942 6 24V24C6 14.058 14.058 6 24 6V6C33.942 6 42 14.058 42 24V24C42 33.942 33.942 42 24 42Z"
                            stroke="#FF4032"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                      <p>{compare.sProducts.eliteS5.keyFeature2}</p>
                    </div>
                    <div className="data_presence">
                      <p className="data_icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                        >
                          <path
                            d="M39.123 14.8867L24.011 29.9987L16.457 22.4427"
                            stroke="#0068FF"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M42 24C42 33.942 33.942 42 24 42C14.058 42 6 33.942 6 24C6 14.058 14.058 6 24 6C26.906 6 29.642 6.706 32.072 7.928"
                            stroke="#0068FF"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                      <p>{compare.sProducts.eliteS5.keyFeature3}</p>
                    </div>
                    <div className="data_presence">
                      <p className="data_icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                        >
                          <path
                            d="M39.123 14.8867L24.011 29.9987L16.457 22.4427"
                            stroke="#0068FF"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M42 24C42 33.942 33.942 42 24 42C14.058 42 6 33.942 6 24C6 14.058 14.058 6 24 6C26.906 6 29.642 6.706 32.072 7.928"
                            stroke="#0068FF"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                      <p>{compare.sProducts.eliteS5.keyFeature4}</p>
                    </div>
                    <div className="data_presence">
                      <p className="data_icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                        >
                          <path
                            d="M29.6598 18.3398L18.3398 29.6598"
                            stroke="#FF4032"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M29.6598 29.6598L18.3398 18.3398"
                            stroke="#FF4032"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M24 42V42C14.058 42 6 33.942 6 24V24C6 14.058 14.058 6 24 6V6C33.942 6 42 14.058 42 24V24C42 33.942 33.942 42 24 42Z"
                            stroke="#FF4032"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                      <p>{compare.sProducts.eliteS5.keyFeature5}</p>
                    </div>
                    <div className="data_presence">
                      <p className="data_icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                        >
                          <path
                            d="M39.123 14.8867L24.011 29.9987L16.457 22.4427"
                            stroke="#0068FF"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M42 24C42 33.942 33.942 42 24 42C14.058 42 6 33.942 6 24C6 14.058 14.058 6 24 6C26.906 6 29.642 6.706 32.072 7.928"
                            stroke="#0068FF"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                      <p>{compare.sProducts.eliteS5.keyFeature6}</p>
                    </div>

                  </div>
                </div>
              </div>
            </Modal.Body>

          </Modal>
        </>
      ) : (
        <div className="product-list-wrapper">
          <div className="container">
            <div className="product-title-wrap">{product.ourProducts}</div>
            <div className="product-box-wrapper">
              <div className="product-box">
                <div className="product-tag">
                  <span>NTSL2</span>
                </div>
                <div className="product-img-wrap">
                  <img src={NTSL2} alt="NTSL2" />
                </div>
                <div className="product-detail-wrap">
                  <div className="product-detail-title-wrap">
                    <div className="product-detail-title">
                      {product.secureLink}
                    </div>
                    <div className="product-detail-subtitle">
                      {product.text1}
                    </div>
                  </div>
                  <div className="product-list-wrap">
                    <div className="product-list-row">
                      <div className="product-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="product-list">{product.text2}</div>
                    </div>
                    <div className="product-list-row">
                      <div className="product-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="product-list">{product.text3}</div>
                    </div>
                  </div>
                </div>
                <div className="learn-more">
                  <span onClick={() => navigate("/product/NTSL2")}>
                    {product.learnMore}
                  </span>
                </div>
              </div>
              <div className="product-box">
                <div className="product-tag">
                  <span>NTSC2</span>
                </div>
                <div className="product-img-wrap">
                  <img src={NTSC2} alt="NTSC2" />
                </div>
                <div className="product-detail-wrap">
                  <div className="product-detail-title-wrap">
                    <div className="product-detail-title">{product.text4}</div>
                    <div className="product-detail-subtitle">
                      {product.text5}
                    </div>
                  </div>
                  <div className="product-list-wrap">
                    <div className="product-list-row">
                      <div className="product-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="product-list">{product.text6}</div>
                    </div>
                    <div className="product-list-row">
                      <div className="product-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="product-list">{product.text7}</div>
                    </div>
                  </div>
                </div>
                <div className="learn-more">
                  <span onClick={() => navigate("/product/NTSC2")}>
                    {product.learnMore}
                  </span>
                </div>
              </div>
              <div className="product-box">
                <div className="product-tag">
                  <span>NTSCM3</span>
                </div>
                <div className="product-img-wrap">
                  <img src={NTSCM3} alt="NTSCM2" />
                </div>
                <div className="product-detail-wrap">
                  <div className="product-detail-title-wrap">
                    <div className="product-detail-title">{product.text8}</div>
                    <div className="product-detail-subtitle">
                      {product.text9}
                    </div>
                  </div>
                  <div className="product-list-wrap">
                    <div className="product-list-row">
                      <div className="product-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="product-list">{product.text10}</div>
                    </div>
                    <div className="product-list-row">
                      <div className="product-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="product-list">{product.text11}</div>
                    </div>
                    <div className="product-list-row">
                      <div className="product-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="product-list">{product.text12}</div>
                    </div>
                  </div>
                </div>
                <div className="learn-more">
                  <span onClick={() => navigate("/product/NTSCM3")}>
                    {product.learnMore}
                  </span>
                </div>
              </div>
              <div className="product-box">
                <div className="product-tag">
                  <span>NTDS2 </span>
                </div>
                <div className="product-img-wrap">
                  <img src={NTDS2} alt="NTDS2 " />
                </div>
                <div className="product-detail-wrap">
                  <div className="product-detail-title-wrap">
                    <div className="product-detail-title">{product.text13}</div>
                    <div className="product-detail-subtitle">
                      {product.text14}
                    </div>
                  </div>
                  <div className="product-list-wrap">
                    <div className="product-list-row">
                      <div className="product-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="product-list">{product.text15}</div>
                    </div>
                    <div className="product-list-row">
                      <div className="product-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="product-list">{product.text16}</div>
                    </div>
                  </div>
                </div>
                <div className="learn-more">
                  <span onClick={() => navigate("/product/NTDS2")}>
                    {product.learnMore}
                  </span>
                </div>
              </div>
              <div className="product-box">
                <div className="product-tag">
                  <span>NTSR3 </span>
                </div>
                <div className="product-img-wrap">
                  <img src={NTSR3} alt="NTSR3 " />
                </div>
                <div className="product-detail-wrap">
                  <div className="product-detail-title-wrap">
                    <div className="product-detail-title">{product.text17}</div>
                    <div className="product-detail-subtitle">
                      {product.text18}
                    </div>
                  </div>
                  <div className="product-list-wrap">
                    <div className="product-list-row">
                      <div className="product-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="product-list">{product.text19}</div>
                    </div>
                    <div className="product-list-row">
                      <div className="product-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="product-list">{product.text20}</div>
                    </div>
                  </div>
                </div>
                <div className="learn-more">
                  <span onClick={() => navigate("/product/NTSR3")}>
                    {product.learnMore}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductsList;
