
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import routers from './routers';
import ScrollToTop from './utils/ScrollToTop/ScrollToTop';
import { MyProvider } from './utils/ContextApi/Context';

function App() {

  return (
    <>
    <MyProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {routers.map((route, idx) => {
            return (
              <Route
                exact={route.exact ? true : false}
                key={idx}
                path={route.path}
                element={
                  <route.layout>
                    {route.component}
                  </route.layout>
                }
              />
            );
          })
          }
        </Routes>
      </BrowserRouter>
      </MyProvider>
    </>
    
  );
}

export default App;
