import React from 'react'
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import './JoinUs.scss'
import { useTranslation } from "react-i18next";
const ReadyToTransform = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const homePage = t("homePage", { returnObjects: true });
  const readyToTransform = homePage.readyToTransform
  return (
    <>
    <div className='ready-transform-wrapper'>
        <div className='rt-content-wrap'>
            <div className='rt-heading-wrapper'>
                <div className='rt-heading'>{readyToTransform.text1}</div>
                <div className='rt-description'>
                {readyToTransform.text2}
                </div>
            </div>
            <div className='rt-order-wrap'>
            <Button variant="light" onClick={() => navigate('/shop')}>{readyToTransform.orderNow}</Button>
            </div>
            <div className='rt-link-wrap'>
                <div className='rt-link-title'>{readyToTransform.text3}</div>
                <div className='rt-link'><span onClick={() => navigate('/contactus')}>{readyToTransform.contact}</span></div>
            </div>
        </div>
    </div>
    </>
  )
}

export default ReadyToTransform