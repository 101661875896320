import React, { useState, useEffect } from "react";
import "./OurProduct.scss";
import { TickMark } from "../../../assets/Icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const OurProduct = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (props.proProductVal === "") {
      setIsCheckedPro("");
    }
  }, [props.proProductVal]);
  const { t } = useTranslation();
  const product = t("productList", { returnObjects: true });
  const [isCheckedPro, setIsCheckedPro] = useState("");
  const handleSelectProduct = (productid) => {
    setIsCheckedPro(productid);
    props.setProductId(productid);
  };

  const [isCurrenyChecked, setIsCurrenyChecked] = useState("Pound");

  const handleSelectCurreny = (curreny) => {
    setIsCurrenyChecked(curreny);
    props.setcurrentCurrency(curreny);
  };

  return (
    <>
      <div className="product-list-wrapper">
        <div className="container">
          <div className="product-title-wrap">{product.ourProducts} </div>
          <div className="toggle-btn-wrapper for_shop_plan bg-transparent pt-3">
            <div className="switches-container switchMoney">
              <input
                type="radio"
                id="switchPound"
                name="switchMoney"
                value="Pound"
                checked={isCurrenyChecked === "Pound"}
                onChange={() => handleSelectCurreny("Pound")}
              />
              <input
                type="radio"
                id="switchDollar"
                name="switchMoney"
                value="Dollar"
                checked={isCurrenyChecked === "Dollar"}
                onChange={() => handleSelectCurreny("Dollar")}
              />
              <input
                type="radio"
                id="switchEuro"
                name="switchMoney"
                value="Euro"
                checked={isCurrenyChecked === "Euro"}
                onChange={() => handleSelectCurreny("Euro")}
              />
              <label htmlFor="switchPound"> £ Pound</label>
              <label htmlFor="switchDollar">$ Dollar</label>
              <label htmlFor="switchEuro">€ Euro</label>
              <div className="switch-wrapper">
                <div className="switch">
                  <div>£ Pound</div>
                  <div>$ Dollar</div>
                  <div>€ Euro</div>
                </div>
              </div>
            </div>
          </div>
          <div className="product-box-wrapper">
            <div
              className={
                isCheckedPro === "s7" ? "active product-box" : "product-box"
              }
            >
              <div className="choose-product-top">
                <div className="product-tag">
                  <span>{product.s7.s7}</span>
                </div>
                <div className="product-select-wrap">
                  <input
                    id="s7"
                    type="checkbox"
                    value="s7"
                    checked={isCheckedPro === "s7"}
                    onChange={() => handleSelectProduct("s7")}
                  />
                  <label htmlFor="s7" className="custom-checkbox">
                    <TickMark />
                  </label>
                </div>
              </div>
              <div className="product-detail-wrap">
                <div className="product-detail-title-wrap">
                  <div className="product-detail-title">
                    {product.s7.heading}
                  </div>
                  <div className="product-detail-subtitle">
                    {product.s7.description}
                  </div>
                </div>
                <div className="product-list-wrap">
                  <div className="product-list-row">
                    <div className="product-icon-wrap">
                      <TickMark />
                    </div>
                    <div className="product-list">{product.s7.text1}</div>
                  </div>
                  <div className="product-list-row">
                    <div className="product-icon-wrap">
                      <TickMark />
                    </div>
                    <div className="product-list">{product.s7.text2}</div>
                  </div>
                </div>
              </div>
              <div
                className="learn-more"
                onClick={() => navigate("/product/secureS7")}
              >
                <span>{product.learnMore}</span>
              </div>
            </div>
            <div
              className={
                isCheckedPro === "s5" ? "active product-box" : "product-box"
              }
            >
              <div className="choose-product-top">
                <div className="product-tag">
                  <span>{product.s5.s5}</span>
                </div>
                <div className="product-select-wrap">
                  <input
                    id="s5"
                    type="checkbox"
                    value="s5"
                    checked={isCheckedPro === "s5"}
                    onChange={() => handleSelectProduct("s5")}
                  />
                  <label htmlFor="s5" className="custom-checkbox">
                    <TickMark />
                  </label>
                </div>
              </div>

              <div className="product-detail-wrap">
                <div className="product-detail-title-wrap">
                  <div className="product-detail-title">
                    {product.s5.heading}
                  </div>
                  <div className="product-detail-subtitle">
                    {product.s5.description}
                  </div>
                </div>
                <div className="product-list-wrap">
                  <div className="product-list-row">
                    <div className="product-icon-wrap">
                      <TickMark />
                    </div>
                    <div className="product-list">{product.s5.text1}</div>
                  </div>
                  <div className="product-list-row">
                    <div className="product-icon-wrap">
                      <TickMark />
                    </div>
                    <div className="product-list">{product.s5.text2}</div>
                  </div>
                </div>
              </div>
              <div
                className="learn-more"
                onClick={() => navigate("/product/proS5")}
              >
                <span>{product.learnMore}</span>
              </div>
            </div>
            <div
              className={
                isCheckedPro === "s5s" ? "active product-box" : "product-box"
              }
            >
              <div className="choose-product-top">
                <div className="product-tag">
                  <span>{product.s5s.s5s}</span>
                </div>
                <div className="product-select-wrap">
                  <input
                    id="s5s"
                    type="checkbox"
                    value="s5s"
                    checked={isCheckedPro === "s5s"}
                    onChange={() => handleSelectProduct("s5s")}
                  />
                  <label htmlFor="s5s" className="custom-checkbox">
                    <TickMark />
                  </label>
                </div>
              </div>

              <div className="product-detail-wrap">
                <div className="product-detail-title-wrap">
                  <div className="product-detail-title">
                    {product.s5s.heading}
                  </div>
                  <div className="product-detail-subtitle">
                    {product.s5s.description}
                  </div>
                </div>
                <div className="product-list-wrap">
                  <div className="product-list-row">
                    <div className="product-icon-wrap">
                      <TickMark />
                    </div>
                    <div className="product-list">{product.s5s.text1}</div>
                  </div>
                  <div className="product-list-row">
                    <div className="product-icon-wrap">
                      <TickMark />
                    </div>
                    <div className="product-list">{product.s5s.text2}</div>
                  </div>
                </div>
              </div>
              <div
                className="learn-more"
                onClick={() => navigate("/product/eliteS5")}
              >
                <span>{product.learnMore}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurProduct;
