import React from 'react'
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import LanfFlag from '../../../assets/images/lang-flag.png'
import Button from 'react-bootstrap/Button';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import './SignUp.scss'
import { EyeIcon } from '../../../assets/Icons';

const SignUp = (props) => {
    const navigate = useNavigate();
    return (
        <>
            <div className='auth-container'>
                <div className='auth-wrapper'>
                    <div className='auth-top'>
                        <div className='auth-title-wrap'>
                            <div className='auth-title'>Create an Account</div>
                            <div className='auth-decription'>Create account first to continue</div>
                        </div>
                        <div className='lang-wrapper-main'>
                            <div className='lang-wrap'>
                                <div className='flag-wraper'><img src={LanfFlag} alt='Flag' /></div>
                                <div className='lang-name-wrap'>English</div>
                            </div>
                        </div>
                    </div>
                    <Form className='signup-form-wrap'>
                        <div className='signup-form'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-row'>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter your first name" />
                                    </div>
                                </div>
                                <div className='col-md-6 mt-3 mt-md-0'>
                                    <div className='form-row'>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter your last name" />
                                    </div>
                                </div>
                            </div>
                            <div className='form-row'>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="text" placeholder="Enter your active Email" />
                            </div>
                            <div className='form-row'>
                                <Form.Label>Company <span>(Optional)</span></Form.Label>
                                <Form.Control type="text" placeholder="Enter your company name" />
                            </div>
                            <div className='form-row'>
                                <Form.Label>Phone Number</Form.Label>
                                <PhoneInput
                                    specialLabel={''}
                                    country={'us'}
                                    {...props}
                                />
                            </div>
                            <div className='form-row'>
                                <Form.Label>Address</Form.Label>
                                <Form.Control as="textarea" placeholder='Enter your address' />
                            </div>
                            <div className='form-row'>
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Enter your Password" />
                                <div className='icon-wrap'><EyeIcon/></div>
                            </div>
                        </div>
                        <div className='btn-wrap'>
                            <Button type='submit' variant='primary'>Register</Button>
                        </div>
                        <div className='have-an-account-wrapper'>Already have an account? <span onClick={() => navigate('/login')}>Login</span></div>
                    </Form>
                </div>

            </div>
        </>
    )
}

export default SignUp