import React, { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import Form from "react-bootstrap/Form";
import "./ServiceCoverage.scss";
import { Chart } from "react-google-charts";

const ServiceCoverage = (props) => {
  console.log("propsprops", props);
  const [value, setValue] = useState("1");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const productServicePlan = props.otherProductVal;

  let currenySign;
  let monthVal;
  let quarterVal;
  let yearVal;

  let options = {
    width: "100%",
    defaultColor: "#0068FF",
    datalessRegionColor: "#CECFD0",
  };

  let data = [];
  if (value === "1") {
    options.region = "150";
    if (productServicePlan !== "product5") {
      if (props.currentCurrency === "Pound") {
        currenySign = "£";
        monthVal = 5.99;
        quarterVal = 16.99;
        yearVal = 54.99;
      } else if (props.currentCurrency === "Dollar") {
        currenySign = "$";
        monthVal = 6.49;
        quarterVal = 18.49;
        yearVal = 59.95;
      } else if (props.currentCurrency === "Euro") {
        currenySign = "€";
        monthVal = 5.99;
        quarterVal = 16.99;
        yearVal = 54.95;
      }
    } else {
      if (props.currentCurrency === "Pound") {
        currenySign = "£";
        monthVal = 4.99;
        quarterVal = 13.99;
        yearVal = 49.99;
      } else if (props.currentCurrency === "Dollar") {
        currenySign = "$";
        monthVal = 5.49;
        quarterVal = 15.49;
        yearVal = 53.95;
      } else if (props.currentCurrency === "Euro") {
        currenySign = "€";
        monthVal = 4.99;
        quarterVal = 13.99;
        yearVal = 49.95;
      }
    }

    data = [
      ["Country"],
      ["Austria"],
      ["Belgium"],
      ["Bulgaria"],
      ["Croatia"],
      ["Cyprus"],
      ["Czech Republic"],
      ["Denmark"],
      ["Estonia"],
      ["Finland"],
      ["France"],
      ["Germany"],
      ["Greece"],
      ["Hungary"],
      ["Iceland"],
      ["Ireland"],
      ["Italy"],
      ["Latvia"],
      ["Liechtenstein"],
      ["Lithuania"],
      ["Luxembourg"],
      ["Malta"],
      ["Netherlands"],
      ["Norway"],
      ["Poland"],
      ["Portugal"],
      ["Romania"],
      ["Slovakia"],
      ["Slovenia"],
      ["Spain"],
      ["Svalbard and Jan Mayen"],
      ["Sweden"],
      ["Turkish Republic of Northern Cyprus"],
      ["United Kingdom"],
      ["Vatican City"],
    ];
  }
  if (value === "2") {
    options.region = "150";

    if (productServicePlan !== "product5") {
      if (props.currentCurrency === "Pound") {
        currenySign = "£";
        monthVal = 6.99;
        quarterVal = 19.99;
        yearVal = 73.99;
      } else if (props.currentCurrency === "Dollar") {
        currenySign = "$";
        monthVal = 7.49;
        quarterVal = 21.49;
        yearVal = 79.95;
      } else if (props.currentCurrency === "Euro") {
        currenySign = "€";
        monthVal = 6.99;
        quarterVal = 19.99;
        yearVal = 73.95;
      }
    } else {
      if (props.currentCurrency === "Pound") {
        currenySign = "£";
        monthVal = 5.99;
        quarterVal = 16.99;
        yearVal = 63.99;
      } else if (props.currentCurrency === "Dollar") {
        currenySign = "$";
        monthVal = 6.49;
        quarterVal = 18.49;
        yearVal = 69.95;
      } else if (props.currentCurrency === "Euro") {
        currenySign = "€";
        monthVal = 5.99;
        quarterVal = 16.99;
        yearVal = 63.95;
      }
    }

    data = [
      ["Country"],
      ["Albania"],
      ["Austria"],
      ["Belarus"],
      ["Belgium"],
      ["Bosnia and Herzegovina"],
      ["Bulgaria"],
      ["Crimea"],
      ["Croatia"],
      ["Cyprus"],
      ["Czech Republic"],
      ["Denmark"],
      ["Estonia"],
      ["Finland"],
      ["France"],
      ["Germany"],
      ["Gibraltar"],
      ["Greece"],
      ["Hungary"],
      ["Iceland"],
      ["Ireland"],
      ["Italy"],
      ["Latvia"],
      ["Liechtenstein"],
      ["Lithuania"],
      ["Luxembourg"],
      ["Malta"],
      ["Mayotte"],
      ["Moldova"],
      ["Monaco"],
      ["Montenegro"],
      ["Netherlands"],
      ["Norway"],
      ["Poland"],
      ["Portugal"],
      ["Macedonia"],
      ["Reunion"],
      ["Romania"],
      ["Russia"],
      ["Serbia"],
      ["Slovakia"],
      ["Slovenia"],
      ["Spain"],
      ["Svalbard and Jan Mayen"],
      ["Sweden"],
      ["Switzerland"],
      ["Turkey"],
      ["Turkish Republic of Northern Cyprus"],
      ["Ukraine"],
      ["United Kingdom"],
      ["Vatican City"],
    ];
  }

  if (value === "3") {
    if (productServicePlan !== "product5") {
      if (props.currentCurrency === "Pound") {
        currenySign = "£";
        monthVal = 7.99;
        quarterVal = 22.79;
        yearVal = 84.49;
      } else if (props.currentCurrency === "Dollar") {
        currenySign = "$";
        monthVal = 8.49;
        quarterVal = 24.49;
        yearVal = 91.95;
      } else if (props.currentCurrency === "Euro") {
        currenySign = "€";
        monthVal = 7.99;
        quarterVal = 22.95;
        yearVal = 84.95;
      }
    } else {
      if (props.currentCurrency === "Pound") {
        currenySign = "£";
        monthVal = 6.99;
        quarterVal = 19.99;
        yearVal = 73.99;
      } else if (props.currentCurrency === "Dollar") {
        currenySign = "$";
        monthVal = 7.49;
        quarterVal = 21.49;
        yearVal = 79.95;
      } else if (props.currentCurrency === "Euro") {
        currenySign = "€";
        monthVal = 6.99;
        quarterVal = 19.99;
        yearVal = 73.95;
      }
    }

    data = [
      ["Country"],
      ["Albania"],
      ["Algeria"],
      ["Argentina"],
      ["Ashmore and Cartier Islands"],
      ["Australia"],
      ["Australian Indian Ocean Territories"],
      ["Austria"],
      ["Azerbaijan"],
      ["Bahrain"],
      ["Baikonur"],
      ["Bangladesh"],
      ["Belarus"],
      ["Belgium"],
      ["Bosnia and Herzegovina"],
      ["Brazil"],
      ["Brunei"],
      ["Bulgaria"],
      ["Burkina Faso"],
      ["Burundi"],
      ["Canada"],
      ["Chile"],
      ["Christmas Island"],
      ["Cocos (Keeling) Islands"],
      ["Colombia"],
      ["Crimea"],
      ["Croatia"],
      ["Cyprus"],
      ["Czech Republic"],
      ["Denmark"],
      ["Dominican Republic"],
      ["East Timor"],
      ["Ecuador"],
      ["Estonia"],
      ["Faroe Islands"],
      ["Finland"],
      ["France"],
      ["Georgia"],
      ["Germany"],
      ["Gibraltar"],
      ["Greece"],
      ["Guantanamo Bay Naval Base"],
      ["Guatemala"],
      ["Guyana"],
      ["Heard Island and McDonald Islands"],
      ["Honduras"],
      ["Hong Kong"],
      ["Hungary"],
      ["Iceland"],
      ["India"],
      ["Iran"],
      ["Iraq"],
      ["Ireland"],
      ["Israel"],
      ["Italy"],
      ["Japan"],
      ["Kazakhstan"],
      ["Kuwait"],
      ["Kyrgyzstan"],
      ["Latvia"],
      ["Liechtenstein"],
      ["Lithuania"],
      ["Luxembourg"],
      ["Malaysia"],
      ["Malta"],
      ["Mauritius"],
      ["Mayotte"],
      ["Mexico"],
      ["Moldova"],
      ["Monaco"],
      ["Mongolia"],
      ["Montenegro"],
      ["Mozambique"],
      ["Myanmar"],
      ["Netherlands"],
      ["New Zealand"],
      ["Nigeria"],
      ["Northern Mariana Islands"],
      ["Norway"],
      ["Pakistan"],
      ["Palestine"],
      ["Panama"],
      ["Paraguay"],
      ["China"],
      ["Peru"],
      ["Philippines"],
      ["Poland"],
      ["Portugal"],
      ["Puerto Rico"],
      ["Macedonia"],
      ["Reunion"],
      ["Romania"],
      ["Russia"],
      ["Saudi Arabia"],
      ["Serbia"],
      ["Singapore"],
      ["Slovakia"],
      ["Slovenia"],
      ["South Africa"],
      ["South Korea"],
      ["Spain"],
      ["Svalbard and Jan Mayen"],
      ["Sweden"],
      ["Switzerland"],
      ["Taiwan"],
      ["Tajikistan"],
      ["Tanzania"],
      ["Thailand"],
      ["Turkey"],
      ["Turkish Republic of Northern Cyprus"],
      ["Ukraine"],
      ["United Kingdom"],
      ["United States Virgin Islands"],
      ["United States"],
      ["Uruguay"],
      ["Uzbekistan"],
      ["Vatican City"],
      ["Vietnam"],
    ];
  }

  if (value === "4") {
    if (productServicePlan !== "product5") {
      if (props.currentCurrency === "Pound") {
        currenySign = "£";
        monthVal = 13.99;
        quarterVal = 39.99;
        yearVal = 144.99;
      } else if (props.currentCurrency === "Dollar") {
        currenySign = "$";
        monthVal = 15.49;
        quarterVal = 43.49;
        yearVal = 156.95;
      } else if (props.currentCurrency === "Euro") {
        currenySign = "€";
        monthVal = 13.99;
        quarterVal = 39.99;
        yearVal = 144.95;
      }
    } else {
      if (props.currentCurrency === "Pound") {
        currenySign = "£";
        monthVal = 13.99;
        quarterVal = 39.99;
        yearVal = 144.99;
      } else if (props.currentCurrency === "Dollar") {
        currenySign = "$";
        monthVal = 15.49;
        quarterVal = 43.49;
        yearVal = 156.95;
      } else if (props.currentCurrency === "Euro") {
        currenySign = "€";
        monthVal = 13.99;
        quarterVal = 39.99;
        yearVal = 144.95;
      }
    }

    data = [
      ["Country"],
      ["Afghanistan"],
      ["Albania"],
      ["Algeria"],
      ["Anguilla"],
      ["Antigua and Barbuda"],
      ["Argentina"],
      ["Armenia"],
      ["Aruba"],
      ["Ashmore and Cartier Islands"],
      ["Australia"],
      ["Australian Indian Ocean Territories"],
      ["Austria"],
      ["Azerbaijan"],
      ["Bahrain"],
      ["Baikonur"],
      ["Bangladesh"],
      ["Barbados"],
      ["Belarus"],
      ["Belgium"],
      ["Belize"],
      ["Benin"],
      ["Bhutan"],
      ["Bolivia"],
      ["Bonaire"],
      ["Bosnia and Herzegovina"],
      ["Botswana"],
      ["Brazil"],
      ["British Virgin Islands"],
      ["Brunei"],
      ["Bulgaria"],
      ["Burkina Faso"],
      ["Burundi"],
      ["Cambodia"],
      ["Cameroon"],
      ["Canada"],
      ["Cayman Islands"],
      ["Central African Republic"],
      ["Chile"],
      ["Christmas Island"],
      ["Cocos (Keeling) Islands"],
      ["Colombia"],
      ["Costa Rica"],
      ["Crimea"],
      ["Croatia"],
      ["CuraÃ§ao"],
      ["Cyprus"],
      ["Czech Republic"],
      ["Democratic Republic of the Congo"],
      ["Denmark"],
      ["Dominica"],
      ["Dominican Republic"],
      ["East Timor"],
      ["Ecuador"],
      ["Egypt"],
      ["El Salvador"],
      ["Estonia"],
      ["Eswatini"],
      ["Faroe Islands"],
      ["Fiji"],
      ["Finland"],
      ["France"],
      ["French Antilles"],
      ["French Guiana"],
      ["French Polynesia"],
      ["Gabon"],
      ["Georgia"],
      ["Germany"],
      ["Ghana"],
      ["Gibraltar"],
      ["Greece"],
      ["Greenland"],
      ["Grenada"],
      ["Guadeloupe"],
      ["Guantanamo Bay Naval Base"],
      ["Guatemala"],
      ["Guernsey"],
      ["Guinea"],
      ["Guinea-Bissau"],
      ["Guyana"],
      ["Haiti"],
      ["Heard Island and McDonald Islands"],
      ["Honduras"],
      ["Hong Kong"],
      ["Hungary"],
      ["Iceland"],
      ["India"],
      ["Indonesia"],
      ["Iran"],
      ["Iraq"],
      ["Ireland"],
      ["Isle of Man"],
      ["Israel"],
      ["Italy"],
      ["Ivory Coast"],
      ["Jamaica"],
      ["Japan"],
      ["Jersey"],
      ["Jordan"],
      ["Kazakhstan"],
      ["Kenya"],
      ["Kosovo"],
      ["Kuwait"],
      ["Kyrgyzstan"],
      ["Laos"],
      ["Latvia"],
      ["Lesotho"],
      ["Liechtenstein"],
      ["Lithuania"],
      ["Luxembourg"],
      ["Macau"],
      ["Madagascar"],
      ["Malawi"],
      ["Malaysia"],
      ["Mali"],
      ["Malta"],
      ["Martinique"],
      ["Mauritania"],
      ["Mauritius"],
      ["Mayotte"],
      ["Mexico"],
      ["Moldova"],
      ["Monaco"],
      ["Mongolia"],
      ["Montenegro"],
      ["Montserrat"],
      ["Morocco"],
      ["Mozambique"],
      ["Myanmar"],
      ["Namibia"],
      ["Nauru"],
      ["Nepal"],
      ["Netherlands"],
      ["New Zealand"],
      ["Nicaragua"],
      ["Niger"],
      ["Nigeria"],
      ["Northern Mariana Islands"],
      ["Norway"],
      ["Oman"],
      ["Pakistan"],
      ["Palestine"],
      ["Panama"],
      ["Papua New Guinea"],
      ["Paraguay"],
      ["China"],
      ["Peru"],
      ["Philippines"],
      ["Poland"],
      ["Portugal"],
      ["Puerto Rico"],
      ["Qatar"],
      ["Macedonia"],
      ["Republic of the Congo"],
      ["Reunion"],
      ["Romania"],
      ["Russia"],
      ["Rwanda"],
      ["Saba"],
      ["Saint Kitts and Nevis"],
      ["Saint Lucia"],
      ["Saint Martin"],
      ["Saint Vincent and the Grenadines"],
      ["Saint-BarthÃ©lemy"],
      ["Samoa"],
      ["Saudi Arabia"],
      ["Senegal"],
      ["Serbia"],
      ["Seychelles"],
      ["Sierra Leone"],
      ["Singapore"],
      ["Sint Eustatius"],
      ["Sint Maarten"],
      ["Slovakia"],
      ["Slovenia"],
      ["South Africa"],
      ["South Korea"],
      ["South Sudan"],
      ["Spain"],
      ["Sri Lanka"],
      ["Sudan"],
      ["Suriname"],
      ["Svalbard and Jan Mayen"],
      ["Sweden"],
      ["Switzerland"],
      ["Taiwan"],
      ["Tajikistan"],
      ["Tanzania"],
      ["Thailand"],
      ["The Gambia"],
      ["Tonga"],
      ["Trinidad and Tobago"],
      ["Tunisia"],
      ["Turkey"],
      ["Turkish Republic of Northern Cyprus"],
      ["Turks and Caicos Islands"],
      ["Uganda"],
      ["Ukraine"],
      ["United Kingdom"],
      ["United States Virgin Islands"],
      ["United States"],
      ["Uruguay"],
      ["Uzbekistan"],
      ["Vatican City"],
      ["Venezuela"],
      ["Vietnam"],
      ["Western Sahara"],
      ["Zambia"],
    ];
  }

  return (
    <>
      {productServicePlan !== "" ? (
        <div className="coverage-container-wrap">
          <div className="container">
            <div className="coverage-content-wrapper">
              <div className="coverage-section-title">Coverage</div>
              <div className="cc-container service">
                <div className="cc-map-wraper">
                  <div className="coverage_map">
                    <Chart
                      chartType="GeoChart"
                      width="100%"
                      height="520px"
                      data={data}
                      options={options}
                    />
                  </div>
                  <div className="coverage_detail">
                    <div className="info">
                      {currenySign}
                      {monthVal} <span>/ Month</span>
                    </div>
                    <div className="info">
                      {currenySign}
                      {quarterVal} <span>/ 3 months</span>
                    </div>
                    <div className="info">
                      {currenySign}
                      {yearVal} <span>/ Year</span>
                    </div>
                  </div>
                </div>
                <div className="cc-country-wrap">
                  <div className="cc-country-title">Coverage</div>
                  <div className="country-list-wrap">
                    <div className="country-list-title">
                      <Form.Select value={value} onChange={handleChange}>
                        <option value="1">Europe Basic</option>
                        <option value="2">Europe Extended</option>
                        <option value="3">Global Basic</option>
                        <option value="4">Global Extended</option>
                      </Form.Select>
                    </div>
                    <Scrollbars autoHide style={{ height: "100%" }}>
                      {value === "1" ? (
                        <div className="country-list">
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Austria"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AT.svg"
                              />
                            </div>
                            <div className="country-name">Austria</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Belgium"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BE.svg"
                              />
                            </div>
                            <div className="country-name">Belgium</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Bulgaria"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BG.svg"
                              />
                            </div>
                            <div className="country-name">Bulgaria</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Croatia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/HR.svg"
                              />
                            </div>
                            <div className="country-name">Croatia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Cyprus"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CY.svg"
                              />
                            </div>
                            <div className="country-name">Cyprus</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Czech Republic"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CZ.svg"
                              />
                            </div>
                            <div className="country-name">Czech Republic</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Denmark"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/DK.svg"
                              />
                            </div>
                            <div className="country-name">Denmark</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Estonia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/EE.svg"
                              />
                            </div>
                            <div className="country-name">Estonia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Estonia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/FI.svg"
                              />
                            </div>
                            <div className="country-name">Finland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="France"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/FR.svg"
                              />
                            </div>
                            <div className="country-name">France</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Germany"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/DE.svg"
                              />
                            </div>
                            <div className="country-name">Germany</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Greece"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GR.svg"
                              />
                            </div>
                            <div className="country-name">Greece</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Hungary"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/HU.svg"
                              />
                            </div>
                            <div className="country-name">Hungary</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Iceland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/IS.svg"
                              />
                            </div>
                            <div className="country-name">Iceland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Ireland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/IE.svg"
                              />
                            </div>
                            <div className="country-name">Ireland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Italy"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/IT.svg"
                              />
                            </div>
                            <div className="country-name">Italy</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Latvia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/LV.svg"
                              />
                            </div>
                            <div className="country-name">Latvia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Liechtenstein"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/LI.svg"
                              />
                            </div>
                            <div className="country-name">Liechtenstein</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Lithuania"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/LT.svg"
                              />
                            </div>
                            <div className="country-name">Lithuania</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Luxembourg"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/LU.svg"
                              />
                            </div>
                            <div className="country-name">Luxembourg</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Malta"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MT.svg"
                              />
                            </div>
                            <div className="country-name">Malta</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Netherland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/NL.svg"
                              />
                            </div>
                            <div className="country-name">Netherland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Norway"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/NO.svg"
                              />
                            </div>
                            <div className="country-name">Norway</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Poland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PL.svg"
                              />
                            </div>
                            <div className="country-name">Poland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Portugal"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PT.svg"
                              />
                            </div>
                            <div className="country-name">Portugal</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Romania"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/RO.svg"
                              />
                            </div>
                            <div className="country-name">Romania</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Slovakia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SK.svg"
                              />
                            </div>
                            <div className="country-name">Slovakia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Slovenia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SI.svg"
                              />
                            </div>
                            <div className="country-name">Slovenia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Spain"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/ES.svg"
                              />
                            </div>
                            <div className="country-name">Spain</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Svalbard and Jan Mayen"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SJ.svg"
                              />
                            </div>
                            <div className="country-name">
                              Svalbard and Jan Mayen
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Sweden"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SE.svg"
                              />
                            </div>
                            <div className="country-name">Sweden</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Turkish Republic of Northern Cyprus"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/TR.svg"
                              />
                            </div>
                            <div className="country-name">
                              Turkish Republic of Northern Cyprus
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="United Kingdom"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GB.svg"
                              />
                            </div>
                            <div className="country-name">United Kingdom</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Vatican City"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/VA.svg"
                              />
                            </div>
                            <div className="country-name">Vatican City</div>
                          </div>
                        </div>
                      ) : value === "2" ? (
                        <div className="country-list">
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Albania"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AL.svg"
                              />
                            </div>
                            <div className="country-name">Albania</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Austria"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AT.svg"
                              />
                            </div>
                            <div className="country-name">Austria</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Belareus"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BY.svg"
                              />
                            </div>
                            <div className="country-name">Belareus</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Belgium"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BE.svg"
                              />
                            </div>
                            <div className="country-name">Belgium</div>
                          </div>

                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Basnia and Herzegovina"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BA.svg"
                              />
                            </div>
                            <div className="country-name">
                              Basnia and Herzegovina
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Bulgaria"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BG.svg"
                              />
                            </div>
                            <div className="country-name">Bulgaria</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="China"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CN.svg"
                              />
                            </div>
                            <div className="country-name">China</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Crimea"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/UA.svg"
                              />
                            </div>
                            <div className="country-name">Crimea</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Croatia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/HR.svg"
                              />
                            </div>
                            <div className="country-name">Croatia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Cyprus"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CY.svg"
                              />
                            </div>
                            <div className="country-name">Cyprus</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Czech Republic"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CZ.svg"
                              />
                            </div>
                            <div className="country-name">Czech Republic</div>
                          </div>

                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Denmark"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/DK.svg"
                              />
                            </div>
                            <div className="country-name">Denmark</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Estonia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/EE.svg"
                              />
                            </div>
                            <div className="country-name">Estonia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Finland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/FI.svg"
                              />
                            </div>
                            <div className="country-name">Finland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="France"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/FR.svg"
                              />
                            </div>
                            <div className="country-name">France</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Germany"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/DE.svg"
                              />
                            </div>
                            <div className="country-name">Germany</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Gibraltar"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GI.svg"
                              />
                            </div>
                            <div className="country-name">Gibraltar</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Greece"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GR.svg"
                              />
                            </div>
                            <div className="country-name">Greece</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Hungary"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/HU.svg"
                              />
                            </div>
                            <div className="country-name">Hungary</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Iceland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/IS.svg"
                              />
                            </div>
                            <div className="country-name">Iceland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Ireland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/IE.svg"
                              />
                            </div>
                            <div className="country-name">Ireland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Italy"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/IT.svg"
                              />
                            </div>
                            <div className="country-name">Italy</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Latvia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/LV.svg"
                              />
                            </div>
                            <div className="country-name">Latvia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Liechtenstein"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/LI.svg"
                              />
                            </div>
                            <div className="country-name">Liechtenstein</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Lithuania"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/LT.svg"
                              />
                            </div>
                            <div className="country-name">Lithuania</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Luxembourg"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/LU.svg"
                              />
                            </div>
                            <div className="country-name">Luxembourg</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Malta"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MT.svg"
                              />
                            </div>
                            <div className="country-name">Malta</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Mayotte"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/YT.svg"
                              />
                            </div>
                            <div className="country-name">Mayotte</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Moldova"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MD.svg"
                              />
                            </div>
                            <div className="country-name">Moldova</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Monaco"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MC.svg"
                              />
                            </div>
                            <div className="country-name">Monaco</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Montenegro"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/ME.svg"
                              />
                            </div>
                            <div className="country-name">Montenegro</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Netherland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/NL.svg"
                              />
                            </div>
                            <div className="country-name">Netherland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Norway"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/NO.svg"
                              />
                            </div>
                            <div className="country-name">Norway</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Poland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PL.svg"
                              />
                            </div>
                            <div className="country-name">Poland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Portugal"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PT.svg"
                              />
                            </div>
                            <div className="country-name">Portugal</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Macedonia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MK.svg"
                              />
                            </div>
                            <div className="country-name">Macedonia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Reunion"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/RE.svg"
                              />
                            </div>
                            <div className="country-name">Reunion</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Romania"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/RO.svg"
                              />
                            </div>
                            <div className="country-name">Romania</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Russia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/RU.svg"
                              />
                            </div>
                            <div className="country-name">Russia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Serbia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/RS.svg"
                              />
                            </div>
                            <div className="country-name">Serbia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Slovakia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SK.svg"
                              />
                            </div>
                            <div className="country-name">Slovakia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Slovenia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SI.svg"
                              />
                            </div>
                            <div className="country-name">Slovenia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Spain"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/ES.svg"
                              />
                            </div>
                            <div className="country-name">Spain</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Svalbard and Jan Mayen"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SJ.svg"
                              />
                            </div>
                            <div className="country-name">
                              Svalbard and Jan Mayen
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Sweden"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SE.svg"
                              />
                            </div>
                            <div className="country-name">Sweden</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Switzerland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CH.svg"
                              />
                            </div>
                            <div className="country-name">Switzerland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Turkey"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/TR.svg"
                              />
                            </div>
                            <div className="country-name">Turkey</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Turkish Republic of Northern Cyprus"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CY.svg"
                              />
                            </div>
                            <div className="country-name">
                              Turkish Republic of Northern Cyprus
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Ukraine"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/UA.svg"
                              />
                            </div>
                            <div className="country-name">Ukraine</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="United Kingdom"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GB.svg"
                              />
                            </div>
                            <div className="country-name">United Kingdom</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Vatican City"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/VA.svg"
                              />
                            </div>
                            <div className="country-name">Vatican City</div>
                          </div>
                        </div>
                      ) : value === "3" ? (
                        <div className="country-list">
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Albania"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AL.svg"
                              />
                            </div>
                            <div className="country-name">Albania</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Algeria"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/DZ.svg"
                              />
                            </div>
                            <div className="country-name">Algeria</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Argentina"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AR.svg"
                              />
                            </div>
                            <div className="country-name">Argentina</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Ashmore and Cartier Islands"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AU.svg"
                              />
                            </div>
                            <div className="country-name">
                              Ashmore and Cartier Islands
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Australia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AU.svg"
                              />
                            </div>
                            <div className="country-name">Australia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Australian Indian Ocean Territories"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AO.svg"
                              />
                            </div>
                            <div className="country-name">
                              Australian Indian Ocean Territories
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Austria"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AT.svg"
                              />
                            </div>
                            <div className="country-name">Austria</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Azerbaijan"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AZ.svg"
                              />
                            </div>
                            <div className="country-name">Azerbaijan</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Bahrain"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BH.svg"
                              />
                            </div>
                            <div className="country-name">Bahrain</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Baikonur"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/KZ.svg"
                              />
                            </div>
                            <div className="country-name">Baikonur</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Bangladesh"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BD.svg"
                              />
                            </div>
                            <div className="country-name">Bangladesh</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Belareus"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BY.svg"
                              />
                            </div>
                            <div className="country-name">Belareus</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Belgium"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BE.svg"
                              />
                            </div>
                            <div className="country-name">Belgium</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Bosnia and Herzegovina"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BA.svg"
                              />
                            </div>
                            <div className="country-name">
                              Bosnia and Herzegovina
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Brazil"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BR.svg"
                              />
                            </div>
                            <div className="country-name">Brazil</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Brunei"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BN.svg"
                              />
                            </div>
                            <div className="country-name">Brunei</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Bulgaria"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BG.svg"
                              />
                            </div>
                            <div className="country-name">Bulgaria</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Burkina Faso"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BF.svg"
                              />
                            </div>
                            <div className="country-name">Burkina Faso</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Burundi"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BI.svg"
                              />
                            </div>
                            <div className="country-name">Burundi</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Canada"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CA.svg"
                              />
                            </div>
                            <div className="country-name">Canada</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Chile"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CL.svg"
                              />
                            </div>
                            <div className="country-name">Chile</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="China"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CN.svg"
                              />
                            </div>
                            <div className="country-name">China</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Christmas Island"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CX.svg"
                              />
                            </div>
                            <div className="country-name">Christmas Island</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Cocos (Keeling) Islands"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CC.svg"
                              />
                            </div>
                            <div className="country-name">
                              Cocos (Keeling) Islands
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Colombia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CO.svg"
                              />
                            </div>
                            <div className="country-name">Colombia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Crimea"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/UA.svg"
                              />
                            </div>
                            <div className="country-name">Crimea</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Croatia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/HR.svg"
                              />
                            </div>
                            <div className="country-name">Croatia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Cyprus"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CY.svg"
                              />
                            </div>
                            <div className="country-name">Cyprus</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Czech Republic"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CZ.svg"
                              />
                            </div>
                            <div className="country-name">Czech Republic</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Denmark"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/DK.svg"
                              />
                            </div>
                            <div className="country-name">Denmark</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Dominican Republic"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/DO.svg"
                              />
                            </div>
                            <div className="country-name">
                              Dominican Republic
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="East Timor"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/TL.svg"
                              />
                            </div>
                            <div className="country-name">East Timor</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Ecuador"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/EC.svg"
                              />
                            </div>
                            <div className="country-name">Ecuador</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Estonia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/EE.svg"
                              />
                            </div>
                            <div className="country-name">Estonia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Faroe Islands"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/FO.svg"
                              />
                            </div>
                            <div className="country-name">Faroe Islands</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Finland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/FI.svg"
                              />
                            </div>
                            <div className="country-name">Finland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="France"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/FR.svg"
                              />
                            </div>
                            <div className="country-name">France</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Georgia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GE.svg"
                              />
                            </div>
                            <div className="country-name">Georgia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Germany"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/DE.svg"
                              />
                            </div>
                            <div className="country-name">Germany</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Gibraltar"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GI.svg"
                              />
                            </div>
                            <div className="country-name">Gibraltar</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Greece"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GR.svg"
                              />
                            </div>
                            <div className="country-name">Greece</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Guantanamo Bay Naval Base"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GT.svg"
                              />
                            </div>
                            <div className="country-name">
                              Guantanamo Bay Naval Base
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Guatemala"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GT.svg"
                              />
                            </div>
                            <div className="country-name">Guatemala</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Guyane"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GY.svg"
                              />
                            </div>
                            <div className="country-name">Guyane</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Heard Island and McDonald Islands"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/HM.svg"
                              />
                            </div>
                            <div className="country-name">
                              Heard Island and McDonald Islands
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Honduras"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/HN.svg"
                              />
                            </div>
                            <div className="country-name">Honduras</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Hong Kong"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/HK.svg"
                              />
                            </div>
                            <div className="country-name">Hong Kong</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Hungary"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/HU.svg"
                              />
                            </div>
                            <div className="country-name">Hungary</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Iceland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/IS.svg"
                              />
                            </div>
                            <div className="country-name">Iceland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="India"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/IN.svg"
                              />
                            </div>
                            <div className="country-name">India</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Iran"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/IR.svg"
                              />
                            </div>
                            <div className="country-name">Iran</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Iraq"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/IQ.svg"
                              />
                            </div>
                            <div className="country-name">Iraq</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Ireland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/IE.svg"
                              />
                            </div>
                            <div className="country-name">Ireland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Israel"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/IL.svg"
                              />
                            </div>
                            <div className="country-name">Israel</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Italy"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/IT.svg"
                              />
                            </div>
                            <div className="country-name">Italy</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Japan"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/JP.svg"
                              />
                            </div>
                            <div className="country-name">Japan</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Kazakhstan"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/KZ.svg"
                              />
                            </div>
                            <div className="country-name">Kazakhstan</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Kuwait"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/KW.svg"
                              />
                            </div>
                            <div className="country-name">Kuwait</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Kyrgyzstan"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/KG.svg"
                              />
                            </div>
                            <div className="country-name">Kyrgyzstan</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Latvia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/LV.svg"
                              />
                            </div>
                            <div className="country-name">Latvia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Liechtenstein"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/LI.svg"
                              />
                            </div>
                            <div className="country-name">Liechtenstein</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Lithuania"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/LT.svg"
                              />
                            </div>
                            <div className="country-name">Lithuania</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Luxembourg"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/LU.svg"
                              />
                            </div>
                            <div className="country-name">Luxembourg</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Malaysia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MY.svg"
                              />
                            </div>
                            <div className="country-name">Malaysia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Malta"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MT.svg"
                              />
                            </div>
                            <div className="country-name">Malta</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Mauritius"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MU.svg"
                              />
                            </div>
                            <div className="country-name">Mauritius</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Mayotte"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/YT.svg"
                              />
                            </div>
                            <div className="country-name">Mayotte</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Mexico"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MX.svg"
                              />
                            </div>
                            <div className="country-name">Mexico</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Moldova"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MD.svg"
                              />
                            </div>
                            <div className="country-name">Moldova</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Monaco"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MC.svg"
                              />
                            </div>
                            <div className="country-name">Monaco</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Mongolia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MN.svg"
                              />
                            </div>
                            <div className="country-name">Mongolia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Montenegro"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/ME.svg"
                              />
                            </div>
                            <div className="country-name">Montenegro</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Mozambique"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MZ.svg"
                              />
                            </div>
                            <div className="country-name">Mozambique</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Myanmar"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MM.svg"
                              />
                            </div>
                            <div className="country-name">Myanmar</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Macedonia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MK.svg"
                              />
                            </div>
                            <div className="country-name">Macedonia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Netherland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/NL.svg"
                              />
                            </div>
                            <div className="country-name">Netherland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="New Zealand"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/NZ.svg"
                              />
                            </div>
                            <div className="country-name">New Zealand</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Nigeria"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/NG.svg"
                              />
                            </div>
                            <div className="country-name">Nigeria</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Northern Mariana Islands"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MP.svg"
                              />
                            </div>
                            <div className="country-name">
                              Northern Mariana Islands
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Norway"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/NO.svg"
                              />
                            </div>
                            <div className="country-name">Norway</div>
                          </div>

                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Pakistan"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PK.svg"
                              />
                            </div>
                            <div className="country-name">Pakistan</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Paraguay"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PY.svg"
                              />
                            </div>
                            <div className="country-name">Paraguay</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Peru"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PE.svg"
                              />
                            </div>
                            <div className="country-name">Peru</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Poland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PL.svg"
                              />
                            </div>
                            <div className="country-name">Poland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Portugal"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PT.svg"
                              />
                            </div>
                            <div className="country-name">Portugal</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Phillippines"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PH.svg"
                              />
                            </div>
                            <div className="country-name">Phillippines</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Palestine"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PS.svg"
                              />
                            </div>
                            <div className="country-name">Palestine</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Panama"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PA.svg"
                              />
                            </div>
                            <div className="country-name">Panama</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Puerto Rico"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PR.svg"
                              />
                            </div>
                            <div className="country-name">Puerto Rico</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Romania"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/RO.svg"
                              />
                            </div>
                            <div className="country-name">Romania</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Russia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/RU.svg"
                              />
                            </div>
                            <div className="country-name">Russia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Reunion"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/RE.svg"
                              />
                            </div>
                            <div className="country-name">Reunion</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Serbia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/RS.svg"
                              />
                            </div>
                            <div className="country-name">Serbia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Slovakia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SK.svg"
                              />
                            </div>
                            <div className="country-name">Slovakia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Slovenia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SI.svg"
                              />
                            </div>
                            <div className="country-name">Slovenia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Spain"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/ES.svg"
                              />
                            </div>
                            <div className="country-name">Spain</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Svalbard and Janmayen"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SJ.svg"
                              />
                            </div>
                            <div className="country-name">
                              Svalbard and Janmayen
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="South Korea"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/KR.svg"
                              />
                            </div>
                            <div className="country-name">South Korea</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Sweden"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SE.svg"
                              />
                            </div>
                            <div className="country-name">Sweden</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Switzerland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CH.svg"
                              />
                            </div>
                            <div className="country-name">Switzerland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="South Arabia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SA.svg"
                              />
                            </div>
                            <div className="country-name">South Arabia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="South Africa"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/ZA.svg"
                              />
                            </div>
                            <div className="country-name">South Africa</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Singapore"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SG.svg"
                              />
                            </div>
                            <div className="country-name">Singapore</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Tanzania"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/TZ.svg"
                              />
                            </div>
                            <div className="country-name">Tanzania</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Turkey"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/TR.svg"
                              />
                            </div>
                            <div className="country-name">Turkey</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Tajikistan"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/TJ.svg"
                              />
                            </div>
                            <div className="country-name">Tajikistan</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Thailand"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/TH.svg"
                              />
                            </div>
                            <div className="country-name">Thailand</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Taiwan"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/TW.svg"
                              />
                            </div>
                            <div className="country-name">Taiwan</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Turkish Republic of Northern Cyprus"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CY.svg"
                              />
                            </div>
                            <div className="country-name">
                              Turkish Republic of Northern Cyprus
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Uruguay"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/UY.svg"
                              />
                            </div>
                            <div className="country-name">Uruguay</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Ukraine"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/UA.svg"
                              />
                            </div>
                            <div className="country-name">Ukraine</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="United Kingdom"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GB.svg"
                              />
                            </div>
                            <div className="country-name">United Kingdom</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="United States"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/US.svg"
                              />
                            </div>
                            <div className="country-name">United States</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Uzbekistan"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/UZ.svg"
                              />
                            </div>
                            <div className="country-name">Uzbekistan</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="United States Virgin Islands"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/VI.svg"
                              />
                            </div>
                            <div className="country-name">
                              United States Virgin Islands
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Vatican City"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/VA.svg"
                              />
                            </div>
                            <div className="country-name">Vatican City</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Vietnam"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/VN.svg"
                              />
                            </div>
                            <div className="country-name">Vietnam</div>
                          </div>
                        </div>
                      ) : (
                        <div className="country-list">
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Afghanistan"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AF.svg"
                              />
                            </div>
                            <div className="country-name">Afghanistan</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Albania"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AL.svg"
                              />
                            </div>
                            <div className="country-name">Albania</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Algeria"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/DZ.svg"
                              />
                            </div>
                            <div className="country-name">Algeria</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Anguilla"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AI.svg"
                              />
                            </div>
                            <div className="country-name">Anguilla</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Antigua and Barbuda"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AG.svg"
                              />
                            </div>
                            <div className="country-name">
                              Antigua and Barbuda
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Argentina"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AR.svg"
                              />
                            </div>
                            <div className="country-name">Argentina</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Armenia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AM.svg"
                              />
                            </div>
                            <div className="country-name">Armenia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Aruba"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AW.svg"
                              />
                            </div>
                            <div className="country-name">Aruba</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Ashmore and Cartier Islands"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AU.svg"
                              />
                            </div>
                            <div className="country-name">
                              Ashmore and Cartier Islands
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Australia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AU.svg"
                              />
                            </div>
                            <div className="country-name">Australia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Australian Indian Ocean Territories"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AU.svg"
                              />
                            </div>
                            <div className="country-name">
                              Australian Indian Ocean Territories
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Austria"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AT.svg"
                              />
                            </div>
                            <div className="country-name">Austria</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Azerbaijan"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/AZ.svg"
                              />
                            </div>
                            <div className="country-name">Azerbaijan</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Bahrain"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BH.svg"
                              />
                            </div>
                            <div className="country-name">Bahrain</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Baikonur"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/KZ.svg"
                              />
                            </div>
                            <div className="country-name">Baikonur</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Bangladesh"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BD.svg"
                              />
                            </div>
                            <div className="country-name">Bangladesh</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Barbados"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BB.svg"
                              />
                            </div>
                            <div className="country-name">Barbados</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Belareus"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BY.svg"
                              />
                            </div>
                            <div className="country-name">Belareus</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Belgium"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BE.svg"
                              />
                            </div>
                            <div className="country-name">Belgium</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Bosnia and Herzegovina"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BA.svg"
                              />
                            </div>
                            <div className="country-name">
                              Bosnia and Herzegovina
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Brazil"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BR.svg"
                              />
                            </div>
                            <div className="country-name">Brazil</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Brunei"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BN.svg"
                              />
                            </div>
                            <div className="country-name">Brunei</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Bulgaria"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BG.svg"
                              />
                            </div>
                            <div className="country-name">Bulgaria</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Burkina Faso"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BF.svg"
                              />
                            </div>
                            <div className="country-name">Burkina Faso</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Burundi"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BI.svg"
                              />
                            </div>
                            <div className="country-name">Burundi</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Belize"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BZ.svg"
                              />
                            </div>
                            <div className="country-name">Belize</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Benin"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BJ.svg"
                              />
                            </div>
                            <div className="country-name">Benin</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Bhutan"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BT.svg"
                              />
                            </div>
                            <div className="country-name">Bhutan</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Bolivia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BO.svg"
                              />
                            </div>
                            <div className="country-name">Bolivia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Bonaire"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BQ.svg"
                              />
                            </div>
                            <div className="country-name">Bonaire</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Botswana"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BW.svg"
                              />
                            </div>
                            <div className="country-name">Botswana</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="British Virgin Islands"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/VG.svg"
                              />
                            </div>
                            <div className="country-name">
                              British Virgin Islands
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Canada"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CA.svg"
                              />
                            </div>
                            <div className="country-name">Canada</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Chile"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CL.svg"
                              />
                            </div>
                            <div className="country-name">Chile</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Christmas Island"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CX.svg"
                              />
                            </div>
                            <div className="country-name">Christmas Island</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Cocos (Keeling) Islands"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CC.svg"
                              />
                            </div>
                            <div className="country-name">
                              Cocos (Keeling) Islands
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Colombia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CO.svg"
                              />
                            </div>
                            <div className="country-name">Colombia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Crimea"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/UA.svg"
                              />
                            </div>
                            <div className="country-name">Crimea</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Croatia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/HR.svg"
                              />
                            </div>
                            <div className="country-name">Croatia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Cyprus"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CY.svg"
                              />
                            </div>
                            <div className="country-name">Cyprus</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Czech Republic"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CZ.svg"
                              />
                            </div>
                            <div className="country-name">Czech Republic</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Cambodia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/KH.svg"
                              />
                            </div>
                            <div className="country-name">Cambodia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Cameroon"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CM.svg"
                              />
                            </div>
                            <div className="country-name">Cameroon</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Cayman Islands"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/KY.svg"
                              />
                            </div>
                            <div className="country-name">Cayman Islands</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Central African Republic"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CF.svg"
                              />
                            </div>
                            <div className="country-name">
                              Central African Republic
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Costa Rica"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CR.svg"
                              />
                            </div>
                            <div className="country-name">Costa Rica</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="CuraÃ§ao"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CW.svg"
                              />
                            </div>
                            <div className="country-name">CuraÃ§ao</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="China"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CN.svg"
                              />
                            </div>
                            <div className="country-name">China</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Democratic Republic of the Congo"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CD.svg"
                              />
                            </div>
                            <div className="country-name">
                              Democratic Republic of the Congo
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Dominica"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/DM.svg"
                              />
                            </div>
                            <div className="country-name">Dominica</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Denmark"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/DK.svg"
                              />
                            </div>
                            <div className="country-name">Denmark</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Dominican Republic"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/DO.svg"
                              />
                            </div>
                            <div className="country-name">
                              Dominican Republic
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="East Timor"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/TL.svg"
                              />
                            </div>
                            <div className="country-name">East Timor</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Ecuador"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/EC.svg"
                              />
                            </div>
                            <div className="country-name">Ecuador</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Estonia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/EE.svg"
                              />
                            </div>
                            <div className="country-name">Estonia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Egypt"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/EG.svg"
                              />
                            </div>
                            <div className="country-name">Egypt</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="El Salvador"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SV.svg"
                              />
                            </div>
                            <div className="country-name">El Salvador</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Eswatini"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SZ.svg"
                              />
                            </div>
                            <div className="country-name">Eswatini</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Faroe Islands"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/FO.svg"
                              />
                            </div>
                            <div className="country-name">Faroe Islands</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Finland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/FI.svg"
                              />
                            </div>
                            <div className="country-name">Finland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="France"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/FR.svg"
                              />
                            </div>
                            <div className="country-name">France</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Fiji"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/FJ.svg"
                              />
                            </div>
                            <div className="country-name">Fiji</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="French Antilles"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/TA.svg"
                              />
                            </div>
                            <div className="country-name">French Antilles</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="French Guiana"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GF.svg"
                              />
                            </div>
                            <div className="country-name">French Guiana</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="French Polynesia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PF.svg"
                              />
                            </div>
                            <div className="country-name">French Polynesia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Georgia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GE.svg"
                              />
                            </div>
                            <div className="country-name">Georgia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Germany"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/DE.svg"
                              />
                            </div>
                            <div className="country-name">Germany</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Gibraltar"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GI.svg"
                              />
                            </div>
                            <div className="country-name">Gibraltar</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Greece"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GR.svg"
                              />
                            </div>
                            <div className="country-name">Greece</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Guantanamo Bay Naval Base"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CU.svg"
                              />
                            </div>
                            <div className="country-name">
                              Guantanamo Bay Naval Base
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Guatemala"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GT.svg"
                              />
                            </div>
                            <div className="country-name">Guatemala</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Guyana"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GY.svg"
                              />
                            </div>
                            <div className="country-name">Guyana</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Gabon"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GA.svg"
                              />
                            </div>
                            <div className="country-name">Gabon</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Ghana"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GH.svg"
                              />
                            </div>
                            <div className="country-name">Ghana</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Greenland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GL.svg"
                              />
                            </div>
                            <div className="country-name">Greenland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Grenada"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GD.svg"
                              />
                            </div>
                            <div className="country-name">Grenada</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Guadeloupe"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GP.svg"
                              />
                            </div>
                            <div className="country-name">Guadeloupe</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Guernsey"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GG.svg"
                              />
                            </div>
                            <div className="country-name">Guernsey</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Guinea"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GN.svg"
                              />
                            </div>
                            <div className="country-name">Guinea</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Guinea-Bissau"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GW.svg"
                              />
                            </div>
                            <div className="country-name">Guinea-Bissau</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Haiti"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/HT.svg"
                              />
                            </div>
                            <div className="country-name">Haiti</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Heard Island and McDonald Islands"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/HM.svg"
                              />
                            </div>
                            <div className="country-name">
                              Heard Island and McDonald Islands
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Honduras"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/HN.svg"
                              />
                            </div>
                            <div className="country-name">Honduras</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Hong Kong"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/HK.svg"
                              />
                            </div>
                            <div className="country-name">Hong Kong</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Hungary"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/HU.svg"
                              />
                            </div>
                            <div className="country-name">Hungary</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Iceland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/IS.svg"
                              />
                            </div>
                            <div className="country-name">Iceland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="India"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/IN.svg"
                              />
                            </div>
                            <div className="country-name">India</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Iran"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/IR.svg"
                              />
                            </div>
                            <div className="country-name">Iran</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Iraq"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/IQ.svg"
                              />
                            </div>
                            <div className="country-name">Iraq</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Ireland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/IE.svg"
                              />
                            </div>
                            <div className="country-name">Ireland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Israel"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/IL.svg"
                              />
                            </div>
                            <div className="country-name">Israel</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Italy"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/IT.svg"
                              />
                            </div>
                            <div className="country-name">Italy</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Indonesia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/ID.svg"
                              />
                            </div>
                            <div className="country-name">Indonesia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Isle of Man"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/IM.svg"
                              />
                            </div>
                            <div className="country-name">Isle of Man</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Ivory Coast"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CI.svg"
                              />
                            </div>
                            <div className="country-name">Ivory Coast</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Japan"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/JP.svg"
                              />
                            </div>
                            <div className="country-name">Japan</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Jamaica"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/JM.svg"
                              />
                            </div>
                            <div className="country-name">Jamaica</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Jersey"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/JE.svg"
                              />
                            </div>
                            <div className="country-name">Jersey</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Jordan"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/JO.svg"
                              />
                            </div>
                            <div className="country-name">Jordan</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Kazakhstan"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/KZ.svg"
                              />
                            </div>
                            <div className="country-name">Kazakhstan</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Kuwait"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/KW.svg"
                              />
                            </div>
                            <div className="country-name">Kuwait</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Kyrgyzstan"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/KG.svg"
                              />
                            </div>
                            <div className="country-name">Kyrgyzstan</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Kenya"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/KE.svg"
                              />
                            </div>
                            <div className="country-name">Kenya</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Kosovo"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/XK.svg"
                              />
                            </div>
                            <div className="country-name">Kosovo</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Latvia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/LV.svg"
                              />
                            </div>
                            <div className="country-name">Latvia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Liechtenstein"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/LI.svg"
                              />
                            </div>
                            <div className="country-name">Liechtenstein</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Lithuania"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/LT.svg"
                              />
                            </div>
                            <div className="country-name">Lithuania</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Luxembourg"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/LU.svg"
                              />
                            </div>
                            <div className="country-name">Luxembourg</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Laos"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/LA.svg"
                              />
                            </div>
                            <div className="country-name">Laos</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Lesotho"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/LS.svg"
                              />
                            </div>
                            <div className="country-name">Lesotho</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Malaysia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MY.svg"
                              />
                            </div>
                            <div className="country-name">Malaysia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Malta"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MT.svg"
                              />
                            </div>
                            <div className="country-name">Malta</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Mauritius"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MU.svg"
                              />
                            </div>
                            <div className="country-name">Mauritius</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Mayotte"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/YT.svg"
                              />
                            </div>
                            <div className="country-name">Mayotte</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Mexico"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MX.svg"
                              />
                            </div>
                            <div className="country-name">Mexico</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Moldova"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MD.svg"
                              />
                            </div>
                            <div className="country-name">Moldova</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Monaco"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MC.svg"
                              />
                            </div>
                            <div className="country-name">Monaco</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Mongolia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MN.svg"
                              />
                            </div>
                            <div className="country-name">Mongolia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Montenegro"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/ME.svg"
                              />
                            </div>
                            <div className="country-name">Montenegro</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Mozambique"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MZ.svg"
                              />
                            </div>
                            <div className="country-name">Mozambique</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Myanmar"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MM.svg"
                              />
                            </div>
                            <div className="country-name">Myanmar</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Macedonia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MK.svg"
                              />
                            </div>
                            <div className="country-name">Macedonia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Macau"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MO.svg"
                              />
                            </div>
                            <div className="country-name">Macau</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Madagascar"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MG.svg"
                              />
                            </div>
                            <div className="country-name">Madagascar</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Malawi"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MW.svg"
                              />
                            </div>
                            <div className="country-name">Malawi</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Mali"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/ML.svg"
                              />
                            </div>
                            <div className="country-name">Mali</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Martinique"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MQ.svg"
                              />
                            </div>
                            <div className="country-name">Martinique</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Mauritania"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MR.svg"
                              />
                            </div>
                            <div className="country-name">Mauritania</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Montserrat"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MS.svg"
                              />
                            </div>
                            <div className="country-name">Montserrat</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Morocco"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MA.svg"
                              />
                            </div>
                            <div className="country-name">Morocco</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Netherland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/NL.svg"
                              />
                            </div>
                            <div className="country-name">Netherland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="New Zealand"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/NZ.svg"
                              />
                            </div>
                            <div className="country-name">New Zealand</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Nigeria"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/NG.svg"
                              />
                            </div>
                            <div className="country-name">Nigeria</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Northern Mariana Islands"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MP.svg"
                              />
                            </div>
                            <div className="country-name">
                              Northern Mariana Islands
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Norway"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/NO.svg"
                              />
                            </div>
                            <div className="country-name">Norway</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Namibia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/NA.svg"
                              />
                            </div>
                            <div className="country-name">Namibia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Nauru"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/NR.svg"
                              />
                            </div>
                            <div className="country-name">Nauru</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Nepal"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/NP.svg"
                              />
                            </div>
                            <div className="country-name">Nepal</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Nicaragua"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/NI.svg"
                              />
                            </div>
                            <div className="country-name">Nicaragua</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Niger"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/NE.svg"
                              />
                            </div>
                            <div className="country-name">Niger</div>
                          </div>

                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Oman"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/OM.svg"
                              />
                            </div>
                            <div className="country-name">Oman</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Pakistan"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PK.svg"
                              />
                            </div>
                            <div className="country-name">Pakistan</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Paraguay"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PY.svg"
                              />
                            </div>
                            <div className="country-name">Paraguay</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Peru"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PE.svg"
                              />
                            </div>
                            <div className="country-name">Peru</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Poland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PL.svg"
                              />
                            </div>
                            <div className="country-name">Poland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Portugal"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PT.svg"
                              />
                            </div>
                            <div className="country-name">Portugal</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Phillippines"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PH.svg"
                              />
                            </div>
                            <div className="country-name">Phillippines</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Palestine"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PS.svg"
                              />
                            </div>
                            <div className="country-name">Palestine</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Panama"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PA.svg"
                              />
                            </div>
                            <div className="country-name">Panama</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Puerto Rico"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PR.svg"
                              />
                            </div>
                            <div className="country-name">Puerto Rico</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Papua New Guinea"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/PG.svg"
                              />
                            </div>
                            <div className="country-name">Papua New Guinea</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Qatar"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/QA.svg"
                              />
                            </div>
                            <div className="country-name">Qatar</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Romania"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/RO.svg"
                              />
                            </div>
                            <div className="country-name">Romania</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Russia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/RU.svg"
                              />
                            </div>
                            <div className="country-name">Russia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Reunion"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/RE.svg"
                              />
                            </div>
                            <div className="country-name">Reunion</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Republic of the Congo"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CG.svg"
                              />
                            </div>
                            <div className="country-name">
                              Republic of the Congo
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Rwanda"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/RW.svg"
                              />
                            </div>
                            <div className="country-name">Rwanda</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Serbia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/RS.svg"
                              />
                            </div>
                            <div className="country-name">Serbia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Slovakia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SK.svg"
                              />
                            </div>
                            <div className="country-name">Slovakia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Slovenia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SI.svg"
                              />
                            </div>
                            <div className="country-name">Slovenia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Spain"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/ES.svg"
                              />
                            </div>
                            <div className="country-name">Spain</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Svalbard and Janmayen"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SJ.svg"
                              />
                            </div>
                            <div className="country-name">
                              Svalbard and Janmayen
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="South Korea"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/KR.svg"
                              />
                            </div>
                            <div className="country-name">South Korea</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Sweden"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SE.svg"
                              />
                            </div>
                            <div className="country-name">Sweden</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Switzerland"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CH.svg"
                              />
                            </div>
                            <div className="country-name">Switzerland</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="South Arabia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SA.svg"
                              />
                            </div>
                            <div className="country-name">South Arabia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="South Africa"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/ZA.svg"
                              />
                            </div>
                            <div className="country-name">South Africa</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Singapore"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SG.svg"
                              />
                            </div>
                            <div className="country-name">Singapore</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Saba"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BQ.svg"
                              />
                            </div>
                            <div className="country-name">Saba</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Saint Kitts and Nevis"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/KN.svg"
                              />
                            </div>
                            <div className="country-name">
                              Saint Kitts and Nevis
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Saint Lucia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/LC.svg"
                              />
                            </div>
                            <div className="country-name">Saint Lucia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Saint Martin"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/MF.svg"
                              />
                            </div>
                            <div className="country-name">Saint Martin</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Saint Vincent and the Grenadines"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/VC.svg"
                              />
                            </div>
                            <div className="country-name">
                              Saint Vincent and the Grenadines
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Saint-BarthÃ©lemy"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BL.svg"
                              />
                            </div>
                            <div className="country-name">
                              Saint-BarthÃ©lemy
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Samoa"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/WS.svg"
                              />
                            </div>
                            <div className="country-name">Samoa</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Senegal"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SN.svg"
                              />
                            </div>
                            <div className="country-name">Senegal</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Seychelles"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SC.svg"
                              />
                            </div>
                            <div className="country-name">Seychelles</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Sierra Leone"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SL.svg"
                              />
                            </div>
                            <div className="country-name">Sierra Leone</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Sint Eustatius"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/BQ.svg"
                              />
                            </div>
                            <div className="country-name">Sint Eustatius</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Sint Maarten"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SX.svg"
                              />
                            </div>
                            <div className="country-name">Sint Maarten</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="South Sudan"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SD.svg"
                              />
                            </div>
                            <div className="country-name">South Sudan</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Sri Lanka"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/LK.svg"
                              />
                            </div>
                            <div className="country-name">Sri Lanka</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Sudan"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SD.svg"
                              />
                            </div>
                            <div className="country-name">Sudan</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Suriname"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/SR.svg"
                              />
                            </div>
                            <div className="country-name">Suriname</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Tanzania"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/TZ.svg"
                              />
                            </div>
                            <div className="country-name">Tanzania</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Turkey"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/TR.svg"
                              />
                            </div>
                            <div className="country-name">Turkey</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Tajikistan"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/TJ.svg"
                              />
                            </div>
                            <div className="country-name">Tajikistan</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Thailand"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/TH.svg"
                              />
                            </div>
                            <div className="country-name">Thailand</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Taiwan"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/TW.svg"
                              />
                            </div>
                            <div className="country-name">Taiwan</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Turkish Republic of Northern Cyprus"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/CY.svg"
                              />
                            </div>
                            <div className="country-name">
                              Turkish Republic of Northern Cyprus
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="The Gambia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GM.svg"
                              />
                            </div>
                            <div className="country-name">The Gambia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Tonga"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/TO.svg"
                              />
                            </div>
                            <div className="country-name">Tonga</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Trinidad and Tobago"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/TT.svg"
                              />
                            </div>
                            <div className="country-name">
                              Trinidad and Tobago
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Tunisia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/TN.svg"
                              />
                            </div>
                            <div className="country-name">Tunisia</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Turks and Caicos Islands"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/TC.svg"
                              />
                            </div>
                            <div className="country-name">
                              Turks and Caicos Islands
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Uruguay"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/UY.svg"
                              />
                            </div>
                            <div className="country-name">Uruguay</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Ukraine"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/UA.svg"
                              />
                            </div>
                            <div className="country-name">Ukraine</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="United Kingdo"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/GB.svg"
                              />
                            </div>
                            <div className="country-name">United Kingdom</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="United States"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/US.svg"
                              />
                            </div>
                            <div className="country-name">United States</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Uzbekistan"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/UZ.svg"
                              />
                            </div>
                            <div className="country-name">Uzbekistan</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="United States Virgin Islands"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/VI.svg"
                              />
                            </div>
                            <div className="country-name">
                              United States Virgin Islands
                            </div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Uganda"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/UG.svg"
                              />
                            </div>
                            <div className="country-name">Uganda</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Vatican City"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/VA.svg"
                              />
                            </div>
                            <div className="country-name">Vatican City</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Vietnam"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/VN.svg"
                              />
                            </div>
                            <div className="country-name">Vietnam</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Venezuela"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/VE.svg"
                              />
                            </div>
                            <div className="country-name">Venezuela</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Western Sahara"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/EH.svg"
                              />
                            </div>
                            <div className="country-name">Western Sahara</div>
                          </div>
                          <div className="country-list-row">
                            <div className="country-icon">
                              <img
                                alt="Zambia"
                                src="http://purecatamphetamine.github.io/country-flag-icons/1x1/ZM.svg"
                              />
                            </div>
                            <div className="country-name">Zambia</div>
                          </div>
                        </div>
                      )}
                    </Scrollbars>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ServiceCoverage;
