import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import "./PlansDetail.scss";
import { ArrowRight } from "../../../assets/Icons";

const PlansDetail = (props) => {
  // const newVal = props.values.newVAl;
  // const oldVal = props.values.oldVal;
  const proProductPlan = props.proProductVal;

  // let changeRate;
  let currencySign;

  // if (oldVal === "GBP" && (newVal === "" || newVal === undefined)) {
  //   // changeRate = 1;
  //   currencySign = "£";
  // }

  // if (oldVal === "GBP" && newVal === "USD") {
  //   // changeRate = 1.25;
  //   currencySign = "$";
  // } else if (oldVal === "GBP" && newVal === "EUR") {
  //   // changeRate = 1.17;
  //   currencySign = "€";
  // } else if (oldVal === "USD" && newVal === "GBP") {
  //   // changeRate = 0.8;
  //   currencySign = "£";
  // } else if (oldVal === "USD" && newVal === "EUR") {
  //   // changeRate = 0.93;
  //   currencySign = "€";
  // } else if (oldVal === "EUR" && newVal === "GBP") {
  //   //  changeRate = 0.86;
  //   currencySign = "£";
  // } else if (oldVal === "EUR" && newVal === "USD") {
  //   // changeRate = 1.07;
  //   currencySign = "$";
  // }

  const navigate = useNavigate();
  let freePrice;
  let basicPrice;
  let enterprisePrice;

  if (proProductPlan === "s7") {
    if (props.currentCurrency === "Pound") {
      currencySign = "£";
      freePrice = 11.99;
      basicPrice = 34.99;
      enterprisePrice = 119;
    } else if (props.currentCurrency === "Dollar") {
      currencySign = "$";
      freePrice = 14.99;
      basicPrice = 43.95;
      enterprisePrice = 148.95;
    } else if (props.currentCurrency === "Euro") {
      currencySign = "€";
      freePrice = 13.95;
      basicPrice = 40.49;
      enterprisePrice = 136.95;
    }
  } else if (proProductPlan === "s5") {
    if (props.currentCurrency === "Pound") {
      currencySign = "£";
      freePrice = 12.99;
      basicPrice = 37.99;
      enterprisePrice = 139;
    } else if (props.currentCurrency === "Dollar") {
      currencySign = "$";
      freePrice = 16.49;
      basicPrice = 47.95;
      enterprisePrice = 173.95;
    } else if (props.currentCurrency === "Euro") {
      currencySign = "€";
      freePrice = 14.95;
      basicPrice = 43.95;
      enterprisePrice = 159.95;
    }
  } else if (proProductPlan === "s5s") {
    if (props.currentCurrency === "Pound") {
      currencySign = "£";
      freePrice = 15.99;
      basicPrice = 45.99;
      enterprisePrice = 172;
    } else if (props.currentCurrency === "Dollar") {
      currencySign = "$";
      freePrice = 19.99;
      basicPrice = 57.95;
      enterprisePrice = 214.95;
    } else if (props.currentCurrency === "Euro") {
      currencySign = "€";
      freePrice = 18.49;
      basicPrice = 52.95;
      enterprisePrice = 197.95;
    }
  }
  return (
    <>
      <div className="plans-details-wrapper remove-after mb-5">
        <div className="container">
          <div className="plans-detail-box-wrapper">
            <div className="plans-detail-box">
              <div className="plans-detail-content">
                <div className="plans-title">Monthly</div>
                <div className="plan-price-wrap">
                  <div className="plan-price">
                    {currencySign}{" "}
                    {/* {newVal === "" || newVal === undefined
                        ? 0
                        : 0 * changeRate} */}
                    {freePrice}
                  </div>
                  {/* <div className="plan-duration">/month</div> */}
                </div>
                {/* <div className="feature-list-wrap">
                    <div className="fl-row">
                      <div className="fl-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="fl-text-wrap">Features</div>
                    </div>
                    <div className="fl-row">
                      <div className="fl-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="fl-text-wrap">Features</div>
                    </div>
                    <div className="fl-row">
                      <div className="fl-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="fl-text-wrap">Features</div>
                    </div>
                  </div> */}
              </div>
              <div className="plans-btn-wrap">
                <Button variant="secondary" onClick={() => navigate("/signup")}>
                  Sign Up <ArrowRight />
                </Button>
              </div>
            </div>
            <div className="plans-detail-box best-deal">
              <div className="best-deal-wrap">Most Popular</div>
              <div className="plans-detail-content">
                <div className="plans-title">Quarterly</div>
                <div className="plan-price-wrap">
                  <div className="plan-price">
                    {currencySign}{" "}
                    {/* {newVal === "" || newVal === undefined
                        ? 19
                        : 19 * changeRate} */}
                    {basicPrice}
                  </div>
                  {/* <div className="plan-duration">/month</div> */}
                </div>
                {/* <div className="feature-list-wrap">
                    <div className="fl-row">
                      <div className="fl-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="fl-text-wrap">Features</div>
                    </div>
                    <div className="fl-row">
                      <div className="fl-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="fl-text-wrap">Features</div>
                    </div>
                    <div className="fl-row">
                      <div className="fl-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="fl-text-wrap">Features</div>
                    </div>
                    <div className="fl-row">
                      <div className="fl-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="fl-text-wrap">Features</div>
                    </div>
                    <div className="fl-row">
                      <div className="fl-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="fl-text-wrap">Features</div>
                    </div>
                    <div className="fl-row">
                      <div className="fl-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="fl-text-wrap">Features</div>
                    </div>
                  </div> */}
              </div>
              <div className="plans-btn-wrap">
                <Button variant="primary" onClick={() => navigate("/payment")}>
                  Subscribe
                  <ArrowRight />
                </Button>
              </div>
            </div>
            <div className="plans-detail-box">
              <div className="plans-detail-content">
                <div className="plans-title">Yearly</div>
                <div className="plan-price-wrap">
                  <div className="plan-price">
                    {currencySign}{" "}
                    {/* {newVal === "" || newVal === undefined
                        ? 39
                        : 39 * changeRate} */}
                    {enterprisePrice}
                  </div>
                  {/* <div className="plan-duration">/month</div> */}
                </div>
                {/* <div className="feature-list-wrap">
                    <div className="fl-row">
                      <div className="fl-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="fl-text-wrap">Features</div>
                    </div>
                    <div className="fl-row">
                      <div className="fl-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="fl-text-wrap">Features</div>
                    </div>
                    <div className="fl-row">
                      <div className="fl-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="fl-text-wrap">Features</div>
                    </div>
                    <div className="fl-row">
                      <div className="fl-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="fl-text-wrap">Features</div>
                    </div>
                    <div className="fl-row">
                      <div className="fl-icon-wrap">
                        <TickMark />
                      </div>
                      <div className="fl-text-wrap">Features</div>
                    </div>
                  </div> */}
              </div>
              <div className="plans-btn-wrap">
                <Button
                  variant="secondary"
                  onClick={() => navigate("/payment")}
                >
                  Subscribe
                  <ArrowRight />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlansDetail;
